import { map } from "lodash";
import {
  months as monthsAll,
  verificationStatementResult as result,
  VerificationStatementCells,
} from "constans/Constans";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import React from "react";
import { sortedAndFilledArrayTypes } from "types/types";

const RowValuesGenerate = (
  type: string,
  resultValues: number,
  sortedAndFilledArray: sortedAndFilledArrayTypes[],
  renderValue: any,
  rowType: string
) => {
  return map(result[resultValues].values, (val, index) => (
    <TableRow sx={{ mb: 5 }} key={index}>
      <TableCell className={"table-cell-custom"}>{val.name}</TableCell>
      {rowType === "partnerRow"
        ? map(monthsAll, (monthValue) =>
            map(sortedAndFilledArray, (monthsData, index) => (
              <React.Fragment key={index}>
                {monthValue.id === monthsData.month ? (
                  <TableCell
                    className={"table-cell-custom"}
                    key={index}
                    sx={{ textAlign: "-webkit-center" }}
                    align="center"
                  >
                    {map(monthsData?.resultValues, (resultValues) =>
                      resultValues?.status === type
                        ? map(resultValues.values, (cellData) =>
                            cellData?.result === val.nameValue
                              ? cellData?.value
                              : ""
                          )
                        : ""
                    )}
                  </TableCell>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))
          )
        : rowType === "totalRow"
        ? map(monthsAll, (monthValue) =>
            map(sortedAndFilledArray, (monthsData, index) => {
              if (monthValue.id === monthsData.month) {
                switch (val.nameValue) {
                  case `${VerificationStatementCells.EMPTY}`:
                    return (
                      <TableCell
                        className={"table-cell-custom"}
                        key={index}
                        sx={{ textAlign: "-webkit-center" }}
                        align="center"
                      >
                        {monthsData.detailsError
                          ? monthsData.detailsError[0]?.errorEMPTY
                          : 0}
                      </TableCell>
                    );

                  case `${VerificationStatementCells.ERROR}`:
                    return (
                      <TableCell
                        className={"table-cell-custom"}
                        key={index}
                        sx={{ textAlign: "-webkit-center" }}
                        align="center"
                      >
                        {monthsData.detailsError
                          ? monthsData.detailsError[0].errorERROR
                          : 0}
                      </TableCell>
                    );

                  case `${VerificationStatementCells.NOTFOUND}`:
                    return (
                      <TableCell
                        className={"table-cell-custom"}
                        key={index}
                        sx={{ textAlign: "-webkit-center" }}
                        align="center"
                      >
                        {monthsData.detailsError
                          ? monthsData.detailsError[0].errorNOTFOUND
                          : 0}
                      </TableCell>
                    );

                  case `${VerificationStatementCells.INACTIVE}`:
                    return (
                      <TableCell
                        className={"table-cell-custom"}
                        key={index}
                        sx={{ textAlign: "-webkit-center" }}
                        align="center"
                      >
                        {monthsData.detailsError
                          ? monthsData.detailsError[0].errorINACTIVE
                          : 0}
                      </TableCell>
                    );
                  case `${VerificationStatementCells.BASIC}`:
                    return (
                      <TableCell
                        className={"table-cell-custom"}
                        key={index}
                        sx={{ textAlign: "-webkit-center" }}
                        align="center"
                      >
                        {monthsData.detailsSuccess
                          ? monthsData.detailsSuccess[0].successBASIC
                          : 0}
                      </TableCell>
                    );
                  case `${VerificationStatementCells.PREMIUM}`:
                    return (
                      <TableCell
                        className={"table-cell-custom"}
                        key={index}
                        sx={{ textAlign: "-webkit-center" }}
                        align="center"
                      >
                        {monthsData.detailsSuccess
                          ? monthsData.detailsSuccess[0].successPREMIUM
                          : 0}
                      </TableCell>
                    );

                  default:
                    return <></>;
                }
              }
            })
          )
        : []}

      <TableCell
        className={"table-cell-custom"}
        sx={{ textAlign: "-webkit-center" }}
      >
        {rowType === "partnerRow"
          ? renderValue?.detailsResultsForPartners[0][type][0][
              `${type}${val.nameValue.replace(/\s/g, "")}`
            ]
          : renderValue?.totalDetailResults[0][type][0][
              `${type}${val.nameValue.replace(/\s/g, "")}`
            ]}
      </TableCell>
    </TableRow>
  ));
};
export default RowValuesGenerate;
