import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { useTranslation } from "react-i18next";
import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import EditPermissionsWebsite from "components/PageEditPermissions/EditPermissionsTab/EditPermissionsWebsite";
import useGetAllRedactorUsersQuery from "hooks/useGetAllRedactorUsersQuery";
import RedactorUsersList from "components/PageEditPermissions/RedactorUsersList/RedactorUsersList";
import useGetEditPermissionsQuery from "hooks/useGetEditPermissonsQuery";
import { Loader } from "components/Loader/Loader";
import { useForm } from "react-hook-form";
import EditPermissionsFilter from "components/PageEditPermissions/EditPermissionsTab/EditPermissionsFilter";
import ParametersEditPermissions from "components/PageEditPermissions/EditParameters/ParametersEditPermissions";
import useUserInformationQuery from "hooks/useUserInformationQuery";
import useUserInformationMutation from "hooks/useUserInformationMutation";
import { useTheme } from "@mui/material/styles";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import CustomButton from "components/Buttons/CustomButton";

export interface FormInputs {
  selectedUserId: any;
  level?: string;
  positionId?: number;
  page?: number;
  itemsPerPage?: number;
  filterByNamePermissionsValue: string;
  filterByLevelPermissions: string;
}
export interface EditorSelectInputs {
  selectedEditor: number;
}

const EditPermissions = () => {
  const { t } = useTranslation();
  const [userPermissionsEnabled, setUserPermissionsEnabled] = useState(false);
  const [userInformationEnabled, setUserInformationEnabled] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openErrorNotChosenUser, setOpenErrorNotChosenUser] = useState(false);
  const [isTreeOpen, setIsTreeOpen] = useState(false);
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { mutate: changeEditor, isLoading: changeEditorLoading } =
    useUserInformationMutation();
  const { data: allRedactorUsers, isLoading: allRedactorUsersLoading } =
    useGetAllRedactorUsersQuery({
      query: { pagination: false },
    });
  const users = allRedactorUsers?.["hydra:member"];
  const {
    control: permissionFormControl,
    handleSubmit: permissionHandleSubmit,
  } = useForm<FormInputs>({
    mode: "onBlur",
    defaultValues: {
      selectedUserId: "",
      level: "SITE",
      positionId: undefined,
      page: 1,
      itemsPerPage: 30,
      filterByLevelPermissions: "MODULE",
      filterByNamePermissionsValue: "",
    },
  });
  const {
    selectedUserId,
    filterByNamePermissionsValue,
    filterByLevelPermissions,
  } = useWatch({
    control: permissionFormControl,
  });

  const { data: userInformationData } = useUserInformationQuery(
    selectedUserId,
    {
      enabled: userInformationEnabled,
      onSettled: () => {
        setUserInformationEnabled(false);
      },
    }
  );

  const { control: redactorFormControl, reset: redactorHandleReset } =
    useForm<EditorSelectInputs>({
      defaultValues: {
        selectedEditor: undefined,
      },
    });
  const { selectedEditor } = useWatch({
    control: redactorFormControl,
  });

  const {
    data: userEditPermissionsData,
    isLoading: userEditPermissionsDataLoading,
  } = useGetEditPermissionsQuery(
    selectedUserId,
    {
      path: {
        id: selectedUserId,
      },
      query: {
        "name[value]":
          filterByNamePermissionsValue !== "" && filterByLevelPermissions !== ""
            ? filterByNamePermissionsValue
            : undefined,

        "name[level]":
          filterByNamePermissionsValue !== "" && filterByLevelPermissions !== ""
            ? filterByLevelPermissions
            : undefined,
      },
    },
    {
      cacheTime: 0,
      enabled: userPermissionsEnabled,
      onSettled: () => {
        setIsTreeOpen(false);
        setUserPermissionsEnabled(false);
      },
    }
  );

  const onSubmitEditRedactorForm = () => {
    if (selectedUserId) {
      changeEditor(
        {
          payload: {
            redactorRoleLevel: selectedEditor !== 11 ? selectedEditor : 0,
          },
          userId: selectedUserId,
        },
        {
          onSuccess: () => {
            setOpenErrorNotChosenUser(false);
            setOpenSuccess(true);
          },
          onError: () => {
            setOpenSuccess(false);
            setOpenError(true);
          },
        }
      );
    } else {
      setOpenSuccess(false);
      setOpenErrorNotChosenUser(true);
    }
  };

  const isSelectedUser = () => {
    if (selectedUserId !== "") {
      setUserPermissionsEnabled(true);
      setUserInformationEnabled(true);
    }
  };
  useEffect(() => {
    redactorHandleReset({
      selectedEditor: userInformationData?.redactorRoleLevel,
    });
  }, [userInformationData, redactorHandleReset]);

  useEffect(() => {
    isSelectedUser();
  }, [selectedUserId]);

  const onSubmitPermissionForm = () => {
    setUserPermissionsEnabled(true);
  };

  return (
    <Card>
      {userEditPermissionsDataLoading && <Loader />}
      <form onSubmit={permissionHandleSubmit(onSubmitPermissionForm)}>
        <CardHeader title={t("Edit Permissions")} />
        <Divider />
        <Grid container spacing={6} item sx={{ mt: 4 }}>
          <Grid item md={5} xs={12}>
            <Box
              sx={{
                maxWidth: 300,
                display: "flex",
                flexDirection: "column",
                mx: onlySmallScreen ? "auto" : 15,
                px: onlySmallScreen ? 5 : "unset",
              }}
            >
              <Typography sx={{ my: 4 }}>
                {t("Select A User To View Their Editing Permissions")}
              </Typography>
              <FormControl>
                <InputLabel id="edit-permissions-user-select-label">
                  {t("User")}
                </InputLabel>
                <Controller
                  control={permissionFormControl}
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      label={t("User")}
                      id="edit-permissions-user-select"
                      labelId="edit-permissions-user-select-label"
                    >
                      {users
                        ? users.map(({ email, id }: any) => (
                            <MenuItem key={id} value={id}>
                              <p>{email}</p>
                            </MenuItem>
                          ))
                        : undefined}
                    </Select>
                  )}
                  name="selectedUserId"
                />
              </FormControl>

              <FormControl sx={{ mt: 4 }}>
                <InputLabel id="edit-permissions-editor-version-select-label">
                  {t("Editor Version")}
                </InputLabel>
                <Controller
                  control={redactorFormControl}
                  name="selectedEditor"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      value={value || ""}
                      onChange={onChange}
                      sx={{
                        width: onlySmallScreen ? "100%" : 300,
                        maxWidth: 300,
                      }}
                      label={t("Editor Version")}
                      id="edit-permissions-editor-version-select"
                      labelId="edit-permissions-editor-version-select-label"
                    >
                      <MenuItem value={11}>{t("Without permissions")}</MenuItem>
                      <MenuItem value={1}>{t("TinyMCE 3.5.x")}</MenuItem>
                      <MenuItem value={2}>{t("TinyMCE 4.5.x")}</MenuItem>
                      <MenuItem value={3}>{t("Next version RTE")}</MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
              <CustomButton
                text={t("Update Editor")}
                style={{ my: 5 }}
                onClickFunction={() => onSubmitEditRedactorForm}
              />
            </Box>
          </Grid>

          <Grid item sx={{ my: 4, mx: 2 }} md={6} xs={12}>
            <RedactorUsersList
              allRedactorUsers={allRedactorUsers}
              allRedactorUsersLoading={allRedactorUsersLoading}
              selectedUserId={selectedUserId}
            />
          </Grid>
          {userEditPermissionsData ? (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    height: "unset",
                    display: "flex",
                    flexDirection: "column",
                    mx: onlyMediumScreen ? 2 : 15,
                  }}
                >
                  <div style={{ marginBottom: "15px" }}>
                    <Typography sx={{ my: 4, mx: onlySmallScreen ? 5 : 0 }}>
                      {t("User Permissions")}
                    </Typography>
                    <EditPermissionsFilter
                      control={permissionFormControl}
                      setIsTreeOpen={setIsTreeOpen}
                    />
                  </div>

                  <EditPermissionsWebsite
                    websiteData={userEditPermissionsData}
                    isTreeOpen={isTreeOpen}
                    setParentUserPermissionsEnabled={setUserPermissionsEnabled}
                    setIsTreeOpen={setIsTreeOpen}
                    selectedUserId={selectedUserId}
                    filterByNamePermissionsValue={filterByNamePermissionsValue}
                    filterByLevelPermissions={filterByLevelPermissions}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Divider />
                <Box
                  sx={{
                    minHeight: 500,
                    maxWidth: 500,
                    display: "flex",
                    flexDirection: "column",
                    mx: onlySmallScreen ? 5 : 15,
                    marginBottom: 15,
                  }}
                >
                  <>
                    <Typography sx={{ my: 4 }}>
                      {t("Set Special Permissions")}
                    </Typography>
                    <ParametersEditPermissions
                      selectedUserId={selectedUserId}
                    />
                  </>
                </Box>
              </Grid>
            </>
          ) : undefined}
        </Grid>
      </form>
      {changeEditorLoading && <Loader />}
      <ChangeParametersModal
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        setOpenErrorNotChosenUser={setOpenErrorNotChosenUser}
        openSuccess={openSuccess}
        openError={openError}
        openErrorNotChosenUser={openErrorNotChosenUser}
        errorMessage={t("Message Error Change Editor")}
        errorNotChosenUserMessage={t("Message Error Not Chosen User")}
        successMessage={t("Message Success Change Editor")}
      />
    </Card>
  );
};

export default EditPermissions;
