import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { formValuePositionType } from "constans/Constans";
import { Loader } from "components/Loader/Loader";
import CustomButton from "components/Buttons/CustomButton";
import { CSVLink } from "react-csv";
import useGetFormHistoryCsvQuery from "hooks/useGetFormHistoryCsvQuery";
import { components } from "schema/schema";
import { format } from "date-fns";

export const useStyles = makeStyles(() => ({
  tableCellCustom: {
    maxWidth: "300px",
    textWrap: "wrap",
  },
}));

interface Props {
  singleFormData?: components["schemas"]["Form.FormDto-answer.extended"];
  formId: string;
}

function AnswersTable({ singleFormData, formId }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [CSVEnabled, setCSVEnabled] = useState(false);
  const { data: CsvData, isLoading: CsvDataLoading } =
    useGetFormHistoryCsvQuery(formId, {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    });
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  return (
    <div className="form-answers-table-wrapper">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Lp.</TableCell>
            <TableCell>{t("Filled Date")}</TableCell>
            <TableCell>{t("IP Address")}</TableCell>
            {singleFormData?.logged && (
              <>
                <TableCell>{t("Last Name")}</TableCell>
                <TableCell>{t("First Name")}</TableCell>
                <TableCell>{t("Email")}</TableCell>
                <TableCell>{t("City")}</TableCell>
                <TableCell>{t("Street")}</TableCell>
                <TableCell>{t("Landline Phone Number")}</TableCell>
                <TableCell>{t("Mobile Phone Number")}</TableCell>
                <TableCell>{t("Card Number")}</TableCell>
              </>
            )}
            {singleFormData?.histories &&
              map(
                singleFormData.histories[0].formQuestions,
                (questionsValues, index) => (
                  <React.Fragment key={index}>
                    {questionsValues.typePosition !== 5 ? (
                      <TableCell className={classes.tableCellCustom}>
                        {questionsValues.text}
                      </TableCell>
                    ) : (
                      <TableCell />
                    )}
                    {map(
                      questionsValues.variantAnswers,
                      (answerValues, index) => (
                        <TableCell
                          className={classes.tableCellCustom}
                          key={index}
                        >
                          {answerValues.text}
                        </TableCell>
                      )
                    )}
                  </React.Fragment>
                )
              )}
          </TableRow>
        </TableHead>
        <TableBody>
          {map(singleFormData?.histories, (singleFormValues, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{singleFormValues.filledDate}</TableCell>
              <TableCell>{singleFormValues.inet}</TableCell>
              {singleFormData?.logged && (
                <>
                  <TableCell>{singleFormValues?.user?.lastName}</TableCell>
                  <TableCell>{singleFormValues?.user?.firstName}</TableCell>
                  <TableCell>{singleFormValues?.user?.email}</TableCell>
                  <TableCell>{singleFormValues?.user?.city}</TableCell>
                  <TableCell>
                    {singleFormValues?.user?.zipCode}
                    <br />
                    {singleFormValues?.user?.street}
                  </TableCell>
                  <TableCell>{singleFormValues?.user?.landlinePhone}</TableCell>
                  <TableCell>{singleFormValues?.user?.mobilePhone}</TableCell>
                  <TableCell>{singleFormValues?.user?.cardNumber}</TableCell>
                  {/*<TableCell>{singleFormValues?.user?.isPaid}</TableCell>*/}
                </>
              )}
              {map(singleFormValues.formQuestions, (questionsValues, index) => (
                <React.Fragment key={index}>
                  {questionsValues.typePosition !==
                  formValuePositionType.BLOCK_QUESTION_FIELD ? (
                    <TableCell className={classes.tableCellCustom}>
                      {questionsValues.text}
                    </TableCell>
                  ) : (
                    <TableCell />
                  )}
                  {map(
                    questionsValues.variantAnswers,
                    (answerValues, index) => (
                      <TableCell
                        className={classes.tableCellCustom}
                        key={index}
                      >
                        {answerValues.typePosition ===
                          formValuePositionType.TEXT_TYPE_FIELD ||
                        answerValues.typePosition ===
                          formValuePositionType.TEXTAREA_TYPE_FIELD ? (
                          <p>{answerValues.value}</p>
                        ) : (answerValues.typePosition ===
                            formValuePositionType.RADIO_TYPE_FIELD ||
                            answerValues.typePosition ===
                              formValuePositionType.SELECT_TYPE_FIELD) &&
                          typeof answerValues.value === "boolean" ? (
                          <Radio checked={answerValues.value} />
                        ) : answerValues.typePosition ===
                            formValuePositionType.MULTISELECT_TYPE_FIELD &&
                          typeof answerValues.value === "boolean" ? (
                          <Checkbox checked={answerValues.value} />
                        ) : (
                          <>
                            <Radio checked />
                            <p style={{ display: "inline" }}>
                              {answerValues.value}
                            </p>
                          </>
                        )}
                      </TableCell>
                    )
                  )}
                </React.Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {CsvDataLoading && <Loader />}
      <CustomButton
        text={t("Generate CSV")}
        style={{ mx: 5, mb: 5, mt: 5 }}
        onClickFunction={() => GetCsv()}
      />
      {CsvData && !CsvDataLoading && (
        <Button
          color="inherit"
          variant="contained"
          className="button-custom-styles"
          sx={{ mx: 5, mb: 5, mt: 5 }}
        >
          <CSVLink
            className={"csv-button-link"}
            data={String(CsvData)}
            filename={`form_answers_history_${format(
              new Date(),
              "yyyy-MM-dd HH:mm:ss"
            )}.csv`}
            target="_blank"
          >
            {t("Download")}
          </CSVLink>
        </Button>
      )}
    </div>
  );
}

export default AnswersTable;
