import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import useGetTransactionsQuery from "hooks/useGetTransactionsQuery";
import dayjs from "dayjs";
import ActionsButtonTransactions from "components/PageEditUser/Transactions/ActionsButtonTransactions";
import RefundTransactionModal from "components/PageEditUser/Transactions/RefundTransactionModal";

type Props = {
  selectedUserId?: number;
};

function TransactionsTableSubPage({ selectedUserId }: Props) {
  const { t } = useTranslation();
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [idTransaction, setIdTransaction] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [transactionsEnabled, setTransactionsEnabled] = useState(true);

  const { data: userTransactions, isLoading: userTransactionsLoading } =
    useGetTransactionsQuery(
      selectedUserId,
      {
        query: {
          page: pageNumber,
          itemsPerPage: itemsPerPage,
          "order[verificationDate]": "desc",
        },
      },
      {
        enabled: transactionsEnabled,
        onSettled: () => {
          setTransactionsEnabled(false);
        },
      }
    );
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "paymentTypeName",
      minWidth: 300,
      headerName: t("Paid Title") as string,
      renderCell: (params) => {
        const paymentTypeName = params.value as string;
        return (
          <p
            title={paymentTypeName}
            style={{ textAlign: "start", cursor: "pointer" }}
          >
            {paymentTypeName}
          </p>
        );
      },
    },
    {
      flex: 0.1,
      field: "comment",
      minWidth: 350,
      headerName: t("Comments") as string,
      renderCell: (params) => {
        const paymentTypeName = params.value as string;
        return (
          <p
            title={paymentTypeName}
            style={{ textAlign: "start", cursor: "pointer" }}
          >
            {paymentTypeName}
          </p>
        );
      },
    },
    {
      flex: 0.1,
      field: "verificationDate",
      minWidth: 150,
      headerName: t("Date Of Paid") as string,
      valueFormatter: (params) =>
        dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
    },
    {
      flex: 0.1,
      field: "amountInBaseUnit",
      headerName: t("Amount") as string,
      minWidth: 100,
      valueFormatter: (params) => {
        const valueFormatted = Number(params.value).toLocaleString();
        return `${valueFormatted} zł`;
      },
    },
    {
      flex: 0.1,
      field: "userEnteredByFullName",
      minWidth: 150,
      headerName: t("Introduced") as string,
    },
    {
      flex: 0.1,
      field: "addedDate",
      minWidth: 150,
      headerName: t("Date Of Entry") as string,
      valueFormatter: (params) =>
        dayjs(params.value).format("YYYY-MM-DD   HH:mm"),
    },
    {
      flex: 0.1,
      minWidth: 90,
      sortable: false,
      disableColumnMenu: true,
      field: "actions",
      headerName: t("Actions") as string,
      renderCell: ({ row }) => (
        <ActionsButtonTransactions
          setIdTransaction={setIdTransaction}
          setShowTransactionModal={setShowTransactionModal}
          id={row["@id"]}
        />
      ),
    },
  ];

  return (
    <Grid item xs={12}>
      <TableBasic
        paginationMode="server"
        initialSortingTable={"verificationDate"}
        columns={columns}
        loading={userTransactionsLoading}
        rows={userTransactions?.["hydra:member"]}
        totalItems={userTransactions?.["hydra:totalItems"]}
        title={t("Transactions")}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        queryFunction={setTransactionsEnabled}
      />
      <RefundTransactionModal
        idTransaction={idTransaction}
        setShowTransactionModal={setShowTransactionModal}
        showTransactionModal={showTransactionModal}
      />
    </Grid>
  );
}

export default TransactionsTableSubPage;
