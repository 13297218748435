import React, { useRef } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { map } from "lodash";
import { months } from "constans/Constans";
import TableBody from "@mui/material/TableBody";
import VerificationStatementRow from "components/PageVerificationStatement/VerificationStatementRow";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RowValuesGenerate from "components/PageVerificationStatement/RowValuesGenerate";
import { useTranslation } from "react-i18next";
import { sortedArrayTypes } from "types/types";
import CustomButton from "components/Buttons/CustomButton";

function VerificationStatementTable({ data, index }: any) {
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const sortData = data.resultForMonths.sort(
    (a: { month: number }, b: { month: number }) => a.month - b.month
  );
  const sortedAndFilledArray: sortedArrayTypes[] | null | undefined = [];
  let previousMonth = 0;
  const tableRef = useRef<HTMLTableElement>(null);
  const copyTableToClipboard = () => {
    if (tableRef.current) {
      const range = document.createRange();
      range.selectNode(tableRef.current);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand("copy");
      window.getSelection()?.removeAllRanges();
    }
  };
  for (const item of sortData) {
    const currentMonth = item.month;
    if (currentMonth - previousMonth > 1) {
      for (let i = previousMonth + 1; i < currentMonth; i++) {
        sortedAndFilledArray.push({ month: i, resultValues: <TableCell /> });
      }
    }
    sortedAndFilledArray.push(item);
    previousMonth = currentMonth;
  }
  for (let i = (previousMonth || 0) + 1; i <= 12; i++) {
    sortedAndFilledArray.push({ month: i, resultValues: <TableCell /> });
  }

  return (
    <TableContainer key={index} component={Paper} sx={{ mb: "50px" }}>
      <Table
        ref={tableRef}
        className="table table-hover"
        id="MyTable"
        sx={{
          minWidth: 700,
          borderCollapse: "separate",
          borderSpacing: "0px 4px",
        }}
        aria-label="spanning table"
      >
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center">
              {t("Partner")}
            </TableCell>
            <TableCell align="center" colSpan={13}>
              {data.year}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            {map(
              months,
              (
                item: {
                  id: string;
                  fullNamePL: string;
                  shortNamePL: string;
                  fullName: string;
                },
                index
              ) => (
                <TableCell key={index} align="center">
                  {item.shortNamePL}
                </TableCell>
              )
            )}
            <TableCell align="center">{t("Sum")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(data.partners, (partners, index) => (
            <VerificationStatementRow
              key={index}
              index={index}
              partners={partners}
            />
          ))}
          <TableRow className={"table-row-summary"}>
            <TableCell
              rowSpan={
                openSuccess && openError
                  ? 8
                  : openSuccess
                  ? 4
                  : openError
                  ? 6
                  : 2
              }
            >
              {t("Sum")}
            </TableCell>

            <TableCell
              className={"table-cell-custom collapse-table-cell-custom"}
            >
              <IconButton
                className={"icon-button-custom"}
                size="small"
                onClick={() => setOpenSuccess(!openSuccess)}
              >
                {openSuccess ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                {t("Successful")}
              </IconButton>
            </TableCell>

            {map(sortedAndFilledArray, (value, index) => (
              <TableCell
                className={"table-cell-custom"}
                key={index}
                align="center"
              >
                {value.successTotalsForMonthAllPartners}
              </TableCell>
            ))}
            <TableCell className={"table-cell-custom"} align="center">
              {data?.totalDetailResults[0].success[0].successTotal}
            </TableCell>
          </TableRow>
          {openSuccess &&
            RowValuesGenerate(
              "success",
              0,
              sortedAndFilledArray,
              data,
              "totalRow"
            )}
          <TableRow className={"table-row-summary-failed"}>
            <TableCell
              className={"table-cell-custom collapse-table-cell-custom"}
            >
              <IconButton
                className={"icon-button-custom"}
                size="small"
                onClick={() => setOpenError(!openError)}
              >
                {openError ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
                {t("Failed")}
              </IconButton>
            </TableCell>
            {map(sortedAndFilledArray, (value, index) => (
              <TableCell
                className={"table-cell-custom"}
                key={index}
                align="center"
              >
                {value.errorTotalsForMonthAllPartners}
              </TableCell>
            ))}
            <TableCell className={"table-cell-custom"} align="center">
              {data?.totalDetailResults[0].error[0].errorTotal}
            </TableCell>
          </TableRow>
          {openError &&
            RowValuesGenerate(
              "error",
              1,
              sortedAndFilledArray,
              data,
              "totalRow"
            )}
        </TableBody>
      </Table>
      <CustomButton
        text={t("Copy Table to Clipboard")}
        type="submit"
        style={{ m: 2, mb: 4 }}
        onClickFunction={() => copyTableToClipboard()}
      />
    </TableContainer>
  );
}

export default VerificationStatementTable;
