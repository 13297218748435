import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  Divider,
  Tooltip,
  Checkbox,
  Typography,
  TextField,
} from "@mui/material";
import { discountCodesOptions } from "constans/Constans";
import TableBasic from "components/TableBasic/TableBasic";
import dayjs from "dayjs";
import useGetDiscountCodesQuery from "hooks/useGetDiscountCodesQuery";
import ActionsButtonDiscountButtonTable from "components/PageDiscountCodes/ActionsButtonDiscountButtonTable";
import EditDiscountModal from "components/PageDiscountCodes/EditDiscountModal";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import ArrowTableHeader from "components/PageUsers/UsersList/Arrow";
import { headerSortTable } from "components/TableBasic/HeaderSortTable";
import { find } from "lodash";
import AddDiscountCode from "components/PageDiscountCodes/AddDiscountCode";
import { discountCodesTypes } from "types/types";

type dateFormatType = {
  value: string | number | Date | dayjs.Dayjs | null | undefined;
};

function DiscountCodes() {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showOnlyActiveCodes, setShowOnlyActiveCodes] = useState(true);
  const [openEditDiscountModal, setOpenEditDiscountModal] = useState(false);
  const [discountRowData, setDiscountRowData] = useState<discountCodesTypes>();
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);
  const [inputValues, setInputValues] = useState({
    paymentTypeName: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    amount: undefined,
    text: undefined,
    quantity: undefined,
    newMember: undefined,
    currentMemberAfter30DaysFromContributionExpiration: undefined,
  });
  const {
    data: discountCodesData,
    isLoading: discountCodesIsLoading,
    refetch,
  } = useGetDiscountCodesQuery({
    query: {
      page: pageNumber,
      itemsPerPage: itemsPerPage,
      isActive: showOnlyActiveCodes ? "true" : "false",
      "paymentType.name": inputValues.paymentTypeName,
      dateFrom: inputValues.dateFrom,
      dateTo: inputValues.dateTo,
      amount: inputValues.amount,
      text: inputValues.text,
      quantity: inputValues.quantity,
      "order[paymentType.name]":
        find(sortOptions, { field: "paymentTypeName" })?.sort || undefined,
      "order[dateFrom]":
        find(sortOptions, { field: "dateFrom" })?.sort || undefined,
      "order[dateTo]":
        find(sortOptions, { field: "dateTo" })?.sort || undefined,
      "order[amount]":
        find(sortOptions, { field: "amount" })?.sort || undefined,
      "order[text]": find(sortOptions, { field: "text" })?.sort || undefined,
      "order[quantity]":
        find(sortOptions, { field: "quantity" })?.sort || undefined,
    },
  });
  const filterRows = (value: string, columnName: string) => {
    setInputValues((inputValues) => ({
      ...inputValues,
      [columnName]: value,
    }));
  };
  const handleChange = (
    e: { target: { value: string } },
    columnName: string
  ) => {
    filterRows(e.target.value, columnName);
  };
  const headerStyles = {
    ".MuiDataGrid-columnHeaders": {
      minHeight: "70px !important",
      maxHeight: "70px !important",
      lineHeight: "70px !important",
    },
    ".MuiDataGrid-columnHeaderTitleContainer": {
      justifyContent: "center",
    },
    ".MuiDataGrid-virtualScroller": {
      marginTop: "70px !important",
      height: "374px !important",
    },
  };
  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "paymentTypeName",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 350,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles" sx={{ width: "250px" }}>
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "paymentTypeName");
              }}
              direction={
                find(sortOptions, { field: "paymentTypeName" })?.sort || null
              }
            >
              {t("Contribution Type")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "250px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "paymentTypeName")}
          />
        </Box>
      ),
    },
    {
      flex: 0.25,
      minWidth: 130,
      field: "dateFrom",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "dateFrom");
              }}
              direction={find(sortOptions, { field: "dateFrom" })?.sort || null}
            >
              {t("Date From")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "90px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "dateFrom")}
          />
        </Box>
      ),
      valueFormatter: (params: dateFormatType) =>
        dayjs(params.value).format("YYYY-MM-DD"),
    },
    {
      flex: 0.15,
      minWidth: 130,
      field: "dateTo",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "dateTo");
              }}
              direction={find(sortOptions, { field: "dateTo" })?.sort || null}
            >
              {t("Expiration Date")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "90px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "dateTo")}
          />
        </Box>
      ),
      valueFormatter: (params: dateFormatType) =>
        dayjs(params.value).format("YYYY-MM-DD"),
    },
    ...discountCodesOptions.map((option) => ({
      field: option.value,
      renderHeader: () => (
        <Box
          className="discount-codes-checkbox-header-wrapper"
          sx={{ height: "auto", lineHeight: "1em", textAlign: "center" }}
        >
          <div>
            <Tooltip title={option.name}>
              <span style={{ cursor: "pointer" }}>{option.shortName}</span>
            </Tooltip>
          </div>
          <Checkbox onChange={(e) => handleChange(e, option.value)} />
        </Box>
      ),
      flex: 0.15,
      minWidth: option.minWidth,
      sortable: false,
      disableColumnMenu: true,
      cellClassName: "cellCheckbox",
      renderCell: (params: {
        row: {
          options: { [x: string]: any; hasOwnProperty: (arg0: string) => any };
        };
      }) => {
        const optionValue =
          params.row.options && params.row.options.hasOwnProperty(option.value)
            ? params.row.options[option.value]
            : false;
        return (
          <>
            <Checkbox checked={optionValue ? optionValue : false} readOnly />
          </>
        );
      },
    })),
    {
      flex: 0.15,
      minWidth: 130,
      field: "amount",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "amount");
              }}
              direction={find(sortOptions, { field: "amount" })?.sort || null}
            >
              {t("Price After Discount")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "130px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "amount")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "text",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "text");
              }}
              direction={find(sortOptions, { field: "text" })?.sort || null}
            >
              {t("Discount Text")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "150px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "text")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 170,
      field: "countDiscountCodeUsed",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(
                  sortOptions,
                  setSortOptions,
                  "countDiscountCodeUsed"
                );
              }}
              direction={
                find(sortOptions, { field: "countDiscountCodeUsed" })?.sort ||
                null
              }
            >
              {t("Number Of Used Codes")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "100px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "countDiscountCodeUsed")}
          />
        </Box>
      ),
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "quantity",
      sortable: false,
      disableColumnMenu: true,
      align: "center",
      renderHeader: () => (
        <Box className="table-header-custom-styles">
          <div style={{ marginBottom: "10px" }}>
            <ArrowTableHeader
              onClick={() => {
                headerSortTable(sortOptions, setSortOptions, "quantity");
              }}
              direction={find(sortOptions, { field: "quantity" })?.sort || null}
            >
              {t("Counter Of Remaining Codes")}
            </ArrowTableHeader>
          </div>
          <TextField
            sx={{ width: "100px" }}
            variant="standard"
            onChange={(e) => handleChange(e, "quantity")}
          />
        </Box>
      ),
    },
    {
      flex: 0.1,
      minWidth: 90,
      sortable: false,
      disableColumnMenu: true,
      field: "actions",
      headerName: t("Actions") as string,
      renderCell: ({
        // @ts-ignore
        row,
      }) => (
        <ActionsButtonDiscountButtonTable
          setDiscountRowData={setDiscountRowData}
          setOpenEditDiscountModal={setOpenEditDiscountModal}
          rowData={row}
        />
      ),
    },
  ];
  return (
    <Card>
      <Box className="discount-codes-table-wrapper" sx={{ m: 3 }}>
        <TableBasic
          paginationMode="server"
          columns={columns}
          loading={discountCodesIsLoading}
          rows={discountCodesData?.["hydra:member"]}
          title={t("Discount Codes")}
          setItemsPerPage={setItemsPerPage}
          totalItems={discountCodesData?.["hydra:totalItems"]}
          setPageNumber={setPageNumber}
          styles={headerStyles}
          additionalHeaderCheckBoxState={showOnlyActiveCodes}
          setAdditionalHeaderCheckBoxState={setShowOnlyActiveCodes}
          headerCheckBoxLabel={t("Show Only Active Codes") as string}
        />
        <EditDiscountModal
          refetch={refetch}
          showDiscountModal={openEditDiscountModal}
          setShowDiscountModal={setOpenEditDiscountModal}
          discountRowData={discountRowData}
        />
      </Box>
      <Divider />
      <Box sx={{ m: 3 }}>
        <Typography sx={{ ml: 2, mb: 5 }}>
          {t("Add New Discount Code")}
        </Typography>
        <AddDiscountCode refetch={refetch} />
      </Box>
    </Card>
  );
}

export default DiscountCodes;
