import { format } from "date-fns";
import { Role } from "helpers/Global";
import i18next from "i18next";

export const dateFormat = format(new Date(), "yyyy-MM-dd");

export const dateTimeFormat = format(new Date(), "yyyy-MM-dd HH:mm:ss");

export const roles = [
  {
    id: 1,
    name: "Administrator",
    nameValue: Role.ROLE_ADMIN,
  },
  {
    id: 2,
    name: "Super User",
    nameValue: Role.ROLE_SUPER_USER,
  },
  {
    id: 4,
    name: "Redaktor",
    nameValue: Role.ROLE_REDACTOR_LEVEL,
  },
  {
    id: 5,
    name: "Pracownik",
    nameValue: Role.ROLE_SII_EMPLOYEE,
  },
  {
    id: 6,
    name: "Partner",
    nameValue: Role.ROLE_PARTNER,
  },
];

export const mobilePhonePrefix = [
  "50",
  "51",
  "53",
  "57",
  "60",
  "66",
  "69",
  "72",
  "73",
  "78",
  "79",
  "88",
];

export const landlinePhonePrefix = [
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "22",
  "23",
  "24",
  "25",
  "26",
  "29",
  "32",
  "33",
  "34",
  "41",
  "42",
  "43",
  "44",
  "46",
  "47",
  "48",
  "52",
  "54",
  "55",
  "56",
  "58",
  "59",
  "61",
  "62",
  "63",
  "65",
  "67",
  "68",
  "71",
  "74",
  "75",
  "76",
  "77",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "89",
  "91",
  "94",
  "95",
];

export const loginHistoryResult = [
  {
    id: 1,
    name: i18next.t("Successful Login"),
    nameValue: "OK",
  },
  {
    id: 2,
    name: i18next.t("User Not Found"),
    nameValue: "ERR_USR",
  },
  {
    id: 3,
    name: i18next.t("Incorrect Password"),
    nameValue: "ERR_PWD",
  },
  {
    id: 4,
    name: i18next.t("Account Is Inactive"),
    nameValue: "ERR_AKT",
  },
  {
    id: 5,
    name: i18next.t("Account Is Blocked"),
    nameValue: "ERR_BLK",
  },
  {
    id: 6,
    name: i18next.t("Recaptcha Error"),
    nameValue: "ERR_REC",
  },
];

export const months = [
  { id: 1, fullNamePL: "Styczeń", shortNamePL: "Sty", fullName: "January" },
  { id: 2, fullNamePL: "Luty", shortNamePL: "Lut", fullName: "February" },
  { id: 3, fullNamePL: "Marzec", shortNamePL: "Mar", fullName: "March" },
  { id: 4, fullNamePL: "Kwiecień", shortNamePL: "Kwi", fullName: "April" },
  { id: 5, fullNamePL: "Maj", shortNamePL: "Maj", fullName: "Mai" },
  { id: 6, fullNamePL: "Czerwiec", shortNamePL: "Cze", fullName: "June" },
  { id: 7, fullNamePL: "Lipiec", shortNamePL: "Lip", fullName: "July" },
  { id: 8, fullNamePL: "Sierpień", shortNamePL: "Sie", fullName: "August" },
  { id: 9, fullNamePL: "Wrzesień", shortNamePL: "Wrz", fullName: "September" },
  {
    id: 10,
    fullNamePL: "Październik",
    shortNamePL: "Paź",
    fullName: "October",
  },
  { id: 11, fullNamePL: "Listopad", shortNamePL: "Lis", fullName: "November" },
  { id: 12, fullNamePL: "Grudzień", shortNamePL: "Gru", fullName: "December" },
];

export const verificationStatementResult = [
  {
    status: "success",
    values: [
      {
        id: 5,
        name: i18next.t("Basic"),
        nameValue: "BASIC",
      },
      {
        id: 6,
        name: i18next.t("Premium"),
        nameValue: "PREMIUM",
      },
    ],
  },
  {
    status: "error",
    values: [
      {
        id: 1,
        name: i18next.t("No Data"),
        nameValue: "EMPTY",
      },
      {
        id: 2,
        name: i18next.t("Incorrect Data"),
        nameValue: "ERROR",
      },
      {
        id: 3,
        name: i18next.t("User Not Found"),
        nameValue: "NOT FOUND",
      },
      {
        id: 4,
        name: i18next.t("Inactive Short"),
        nameValue: "INACTIVE",
      },
    ],
  },
];

export enum VerificationStatementCells {
  EMPTY = "EMPTY",
  ERROR = "ERROR",
  NOTFOUND = "NOT FOUND",
  INACTIVE = "INACTIVE",
  BASIC = "BASIC",
  PREMIUM = "PREMIUM",
}

export const verificationHistoryResult = [
  {
    id: 1,
    name: i18next.t("No Data"),
    nameValue: "EMPTY",
  },
  {
    id: 2,
    name: i18next.t("Incorrect Data"),
    nameValue: "ERROR",
  },
  {
    id: 3,
    name: i18next.t("User Not Found"),
    nameValue: "NOT FOUND",
  },
  {
    id: 4,
    name: i18next.t("Not An Active Member"),
    nameValue: "INACTIVE",
  },
  {
    id: 5,
    name: i18next.t("Active Member With Basic Contribution"),
    nameValue: "BASIC",
  },
  {
    id: 6,
    name: i18next.t("Active Member With Premium Contribution"),
    nameValue: "PREMIUM",
  },
];

export const formValueTypes = [
  {
    id: 0,
    value: 0,
    name: i18next.t("Select Of Many"),
  },
  {
    id: 1,
    value: 1,
    name: i18next.t("One Of Many"),
  },
  {
    id: 2,
    value: 2,
    name: i18next.t("Text Line"),
  },
  {
    id: 3,
    value: 3,
    name: i18next.t("Textarea"),
  },
  {
    id: 4,
    value: 4,
    name: i18next.t("Select List"),
  },
  {
    id: 5,
    value: 5,
    name: i18next.t("Separator In List"),
  },
  {
    id: 6,
    value: 6,
    name: i18next.t("List Position"),
  },
  {
    id: 7,
    value: 7,
    name: i18next.t("Questions Block"),
  },
];

export const discountCodesOptions = [
  {
    id: 1,
    minWidth: 80,
    value: "newMember",
    name: i18next.t("First Contribution"),
    shortName: i18next.t("First Contribution Short"),
  },
  {
    id: 2,
    minWidth: 80,
    value: "memberUnder26Years",
    name: i18next.t("More Than 30 Days From The Expiry Of The Contribution"),
    shortName: i18next.t(
      "More Than 30 Days From The Expiry Of The Contribution Short"
    ),
  },
  {
    id: 3,
    minWidth: 80,
    value: "currentMemberExtendedMembership",
    name: i18next.t("30 Days Or Less From The Expiry Of The Contribution"),
    shortName: i18next.t(
      "30 Days Or Less From The Expiry Of The Contribution Short"
    ),
  },
  {
    id: 4,
    minWidth: 130,
    value: "currentMemberBefore30DaysFromContributionExpiration",
    name: i18next.t("Contribution Paid At The Basic Level"),
    shortName: i18next.t("Contribution Paid At The Basic Level Short"),
  },

  {
    id: 5,
    minWidth: 120,
    value: "currentMemberBasicMembership",
    name: i18next.t("Contribution Paid At The Premium Level"),
    shortName: i18next.t("Contribution Paid At The Premium Level Short"),
  },

  {
    id: 6,
    minWidth: 80,
    value: "currentMemberAfter30DaysFromContributionExpiration",
    name: i18next.t("26 Years Old Or Less"),
    shortName: i18next.t("26 Years Old Or Less Short"),
  },

  {
    id: 7,
    minWidth: 80,
    value: "member20YearsAndAbove",
    name: i18next.t("More Than 26 Years Old"),
    shortName: i18next.t("More Than 26 Years Old Short"),
  },
];

export const formValidateValues = [
  {
    name: "email",
    value: "email",
  },
  {
    name: "potwierdzenie na ten email",
    value: "potwierdzenie_na_ten_email",
  },
  {
    name: "telefon lub fax",
    value: "tel_fax",
  },
  {
    name: "liczba całkowita",
    value: "liczba_calkowita",
  },
  {
    name: "liczba całkowita dodatnia",
    value: "liczba_calkowita_dodatnia",
  },
  {
    name: "imię lub nazwisko",
    value: "imie_lub_nazwisko",
  },
  {
    name: "kod pocztowy",
    value: "kod_pocztowy",
  },
  {
    name: "NIP",
    value: "NIP",
  },
];
export enum formValuePositionType {
  MULTISELECT_TYPE_FIELD = 0,
  RADIO_TYPE_FIELD = 1,
  TEXT_TYPE_FIELD = 2,
  TEXTAREA_TYPE_FIELD = 3,
  SELECT_TYPE_FIELD = 4,
  BLOCK_QUESTION_FIELD = 5,
}

export enum addTransactionType {
  KEEP_CONTINUITY = 0,
  DONT_KEEP_CONTINUITY = 1,
  SUPPLEMENT_FOR_WHOLE_PERIOD = 2,
  SUPPLEMENT_FOR_NOW_TO_END_PERIOD = 3,
  POINT = 4,
  FORM = 5,
}

export const userVerificationStatusType = [
  {
    id: 0,
    value: 0,
    name: i18next.t("No Profile"),
  },
  {
    id: 1,
    value: 1,
    name: i18next.t("Registered"),
  },
  {
    id: 2,
    value: 2,
    name: i18next.t("Not Paid"),
  },
  {
    id: 3,
    value: 3,
    name: i18next.t("Basic"),
  },
  {
    id: 4,
    value: 4,
    name: i18next.t("Extended"),
  },
];
