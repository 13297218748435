import { FormInputs, LoggedState, RegisteredState } from "pages/Users/Users";
import { Control } from "react-hook-form";
import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import useUsersFilterQuery from "hooks/useUsersFilterQuery";
import { GridSortModel } from "@mui/x-data-grid";
import { find } from "lodash";
import useGenerateCsvQuery from "hooks/useGenerateCsvQuery";
type Props = {
  control: Control<FormInputs>;
};

function useUserList({ control }: Props) {
  const [sortOptions, setSortOptions] = useState<GridSortModel>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [CSVEnabled, setCSVEnabled] = useState(false);

  function getQueryParams(paramNames: any[]) {
    return paramNames.reduce(
      (acc, current) =>
        current === userParameters ? ((acc[current] = searchValue), acc) : acc,
      {}
    );
  }

  const {
    roleName,
    usersAndMembersIsPaidUsersValue,
    usersAndMembersIsPaidUsers,
    usersAndMembersIsActiveMember,
    usersAndMembersIsActiveMemberValue,
    userParameters,
    searchValue,
    activityLoggedOrNotLoggedProperties,
    activityLoggedOrNotLoggedChecked,
    activityLoggedOrNotLoggedDaysField,
    activityRegisteredOrNotRegisteredProperties,
    activityRegisteredOrNotRegisteredChecked,
    activityRegisteredOrNotRegisteredDaysField,
    usersDepartmentsValue,
    crmFilledAllOrOneFields,
    crmFilledFields,
    joinMethodAndOrCrm,
    crmBlankAllOrOneNoneFields,
    crmBlankFields,
    crmContainTextField,
    containTextField,
    crmDateFrom,
    crmDateTo,
  } = useWatch({ control });
  const parameters = {
    query: {
      page: pageNumber,
      itemsPerPage: itemsPerPage,
      searchAll:
        userParameters === "searchAll" && searchValue !== ""
          ? searchValue
          : undefined,
      ...getQueryParams([
        "name",
        "email",
        "city",
        "phone",
        "zipCode",
        "street",
      ]),
      membership: usersAndMembersIsPaidUsers
        ? usersAndMembersIsPaidUsersValue
        : undefined,
      member: usersAndMembersIsActiveMember
        ? usersAndMembersIsActiveMemberValue
        : undefined,
      lastDaysLoggedWithin:
        activityLoggedOrNotLoggedChecked &&
        activityLoggedOrNotLoggedProperties === LoggedState.Logged
          ? activityLoggedOrNotLoggedDaysField
          : undefined,
      lastDaysNotLoggedWithin:
        activityLoggedOrNotLoggedChecked &&
        activityLoggedOrNotLoggedProperties === LoggedState.NotLogged
          ? activityLoggedOrNotLoggedDaysField
          : undefined,

      lastDaysRegisteredWithin:
        activityRegisteredOrNotRegisteredChecked &&
        activityRegisteredOrNotRegisteredProperties ===
          RegisteredState.Registered
          ? activityRegisteredOrNotRegisteredDaysField
          : undefined,
      lastDaysNotRegisteredWithin:
        activityRegisteredOrNotRegisteredChecked &&
        activityRegisteredOrNotRegisteredProperties ===
          RegisteredState.NotRegistered
          ? activityRegisteredOrNotRegisteredDaysField
          : undefined,

      "department.id": usersDepartmentsValue
        ? usersDepartmentsValue
        : undefined,

      roles: roleName ? roleName : undefined,

      ["fieldsCRM[" + crmFilledAllOrOneFields + "]"]: crmFilledAllOrOneFields
        ? crmFilledFields
        : undefined,

      "fieldsCRM[joinMethod]":
        crmFilledFields?.length || crmBlankFields?.length
          ? joinMethodAndOrCrm
          : undefined,

      ["fieldsCRM[" + crmBlankAllOrOneNoneFields + "]"]:
        crmBlankAllOrOneNoneFields ? crmBlankFields : undefined,

      ["fieldsCRM[" + crmContainTextField + "]"]:
        crmFilledFields?.length && containTextField !== ""
          ? containTextField
          : undefined,

      "fieldsCRM[entryDateFrom]":
        crmFilledFields?.length && crmDateTo
          ? new Date(crmDateFrom).toLocaleDateString()
          : null,

      "fieldsCRM[entryDateTo]":
        crmFilledFields?.length && crmDateTo
          ? new Date(crmDateTo).toLocaleDateString()
          : null,

      "orderBy[cardNumber]": find(sortOptions, { field: "cardNumber" })?.sort,

      "orderBy[fullName]": find(sortOptions, { field: "fullName" })?.sort,

      "orderBy[address]": find(sortOptions, { field: "address" })?.sort,

      "orderBy[mainEmail]": find(sortOptions, { field: "mainEmail" })?.sort,

      "orderBy[mainPhone]": find(sortOptions, { field: "mainPhone" })?.sort,

      "orderBy[birthDate]": find(sortOptions, { field: "birthDate" })?.sort,
    },
  };

  const { data: userFiltersData, isLoading } = useUsersFilterQuery(parameters, {
    enabled: filterEnabled || sortOptions.length !== 0,
    onSettled: () => {
      setFilterEnabled(false);
    },
  });
  const { data: CsvData, isLoading: CsvDataLoading } = useGenerateCsvQuery(
    parameters,
    {
      enabled: CSVEnabled,
      onSettled: () => {
        setCSVEnabled(false);
      },
    }
  );

  return {
    data: userFiltersData?.["hydra:member"],
    isLoading,
    setFilterEnabled,
    setCSVEnabled,
    csvData: CsvData,
    csvDataLoading: CsvDataLoading,
    sortOptions,
    setSortOptions,
    pageNumber,
    setPageNumber,
    itemsPerPage,
    setItemsPerPage,
    totalItems: userFiltersData?.["hydra:totalItems"],
  };
}
export default useUserList;
