import Home from "pages/Home/Home";
import Login from "pages/Login/Login";
import ActiveSessions from "pages/ActiveSessions/ActiveSessions";
import Users from "pages/Users/Users";
import EditPermissions from "pages/EditPermissions/EditPermissions";
import EditUser from "pages/EditUser/EditUser";
import MembershipVerification from "pages/MembershipVerification/MembershipVerification";
import JunctionProfiles from "pages/JunctionProfiles/JunctionProfiles";
import AddUser from "pages/AddUser/AddUser";
import ContactTypes from "pages/ContactTypes/ContactTypes";
import CheckingProfiles from "pages/CheckingProfiles/CheckingProfiles";
import { Role } from "helpers/Global";
import EditPoll from "pages/EditPoll/EditPoll";
import EditForms from "pages/EditForms/EditForms";
import VerificationHistory from "pages/VerificationHistory/VerificationHistory";
import LoginHistory from "pages/LoginHistory/LoginHistory";
import DiscountCodes from "pages/DiscountCodes/DiscountCodes";
import VerificationStatement from "pages/VerificationStatement/VerificationStatement";
import ChangePassword from "pages/ChangePassword/ChangePassword";
import EditSubscriptions from "pages/EditSubscriptions/EditSubscriptions";
import FormAnswers from "pages/FormAnswers/FormAnswers";

export const routes = {
  HOME: {
    path: "/",
    component: Home,
    role: [Role.ROLE_USER],
  },
  LOGIN: {
    path: "/login",
    component: Login,
    role: [""],
  },
  ACTIVE_SESSIONS: {
    path: "/active-sessions",
    component: ActiveSessions,
    role: [Role.ROLE_ADMIN],
  },
  EDIT_PERMISSIONS: {
    path: "/edit-permissions",
    component: EditPermissions,
    role: [Role.ROLE_SUPER_USER],
  },
  USERS: {
    path: "/users",
    component: Users,
    role: [Role.ROLE_ADMIN, Role.ROLE_USER],
  },
  EDIT_USER: {
    path: "/users/edit/:id",
    component: EditUser,
    role: ["ROLE_ADMIN"],
  },
  MEMBERSHIP_VERIFICATION: {
    path: "/membership-verification",
    component: MembershipVerification,
    role: [
      Role.ROLE_ADMIN,
      Role.ROLE_SUPER_USER,
      Role.ROLE_SII_EMPLOYEE,
      Role.ROLE_PARTNER,
    ],
  },
  JUNCTION_PROFILES: {
    path: "/junction-profiles",
    component: JunctionProfiles,
    role: [Role.ROLE_SUPER_USER],
  },
  ADD_USER: {
    path: "/add-user",
    component: AddUser,
    role: [Role.ROLE_ADMIN],
  },
  CHECKING_OF_PROFILES: {
    path: "/checking-profiles",
    component: CheckingProfiles,
    role: [Role.ROLE_ADMIN, Role.ROLE_SUPER_USER, Role.ROLE_SII_EMPLOYEE],
  },
  CHANGE_PASSWORD: {
    path: "/change-password",
    component: ChangePassword,
    role: [
      Role.ROLE_ADMIN,
      Role.ROLE_USER,
      Role.ROLE_REDACTOR_LEVEL,
      Role.ROLE_SUPER_USER,
      Role.ROLE_SII_EMPLOYEE,
    ],
  },
  LOGIN_HISTORY: {
    path: "/login-history",
    component: LoginHistory,
    role: [Role.ROLE_ADMIN],
  },
  VERIFICATION_STATEMENT: {
    path: "verification-statement",
    component: VerificationStatement,
    role: [Role.ROLE_ADMIN],
  },
  VERIFICATION_HISTORY: {
    path: "/verification-history",
    component: VerificationHistory,
    role: [Role.ROLE_ADMIN, Role.ROLE_PARTNER],
  },
  ADD_POLL: {
    path: "/add-poll",
    component: EditPoll,
    role: [Role.ROLE_ADMIN],
  },
  CONTACT_TYPES: {
    path: "/contact-types",
    component: ContactTypes,
    role: [Role.ROLE_SUPER_USER],
  },
  EDIT_SUBSCRIPTIONS: {
    path: "/edit-subscriptions",
    component: EditSubscriptions,
    role: [Role.ROLE_ADMIN],
  },
  DISCOUNT_CODES: {
    path: "/discount-codes",
    component: DiscountCodes,
    role: [Role.ROLE_ADMIN],
  },
  EDIT_FORMS: {
    path: "/edit-forms",
    component: EditForms,
    role: [Role.ROLE_ADMIN],
  },
  FORM_ANSWERS: {
    path: "/form-answers",
    component: FormAnswers,
    role: [Role.ROLE_ADMIN],
  },
};
