import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { GridSortDirection } from "@mui/x-data-grid";
import { Button } from "@mui/material";

type Props = {
  direction: GridSortDirection;
  children: React.ReactNode;
  onClick: () => void;
};

function ArrowTableHeader({ direction, children, onClick }: Props) {
  return (
    <>
      {children}
      {direction === "asc" && (
        <ArrowDownwardIcon style={{ fontSize: "medium" }} onClick={onClick} />
      )}
      {direction === "desc" && (
        <ArrowUpwardIcon style={{ fontSize: "medium" }} onClick={onClick} />
      )}
      {!direction && (
        <Button
          tabIndex={-1}
          title="Sort"
          sx={{ minWidth: "28px", paddingLeft: 0 }}
        >
          <svg
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall MuiDataGrid-sortIcon css-ptiqhd-MuiSvgIcon-root"
            focusable="false"
            width="16px"
            height="16px"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ArrowUpwardIcon"
            onClick={onClick}
          >
            <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z" />
          </svg>
          <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root" />
        </Button>
      )}
    </>
  );
}

export default ArrowTableHeader;
