import React, { useState } from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableCell from "@mui/material/TableCell";
import useEditPermissionsMutation from "hooks/useEditPermissionsMutation";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";

type Rows = {
  id: number;
  name: string;
  permission: number;
};
type Props = {
  row: Rows;
  selectedUserId: number;
};

function EditPermissionSpecialSelect({ row, selectedUserId }: Props) {
  const { t } = useTranslation();

  const { mutate } = useEditPermissionsMutation();
  const [permission, setPermission] = useState();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleChange = (event: any) => {
    let level = "REDACTOR_PARAMETER";
    let positionId = row.id;
    let newPermission = event.target.value;

    mutate(
      {
        payload: {
          0: {
            level: level,
            positionId: positionId,
            newPermission: newPermission,
          },
        },
        userId: selectedUserId,
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
        },
        onError: () => {
          setOpenError(true);
        },
      }
    );
  };

  return (
    <>
      <TableCell component="th" scope="row">
        {row?.name}
      </TableCell>
      <TableCell align="center">
        <FormControl>
          <Select
            id={`edit-permissions-user-select${row?.id}`}
            labelId="edit-permissions-user-select-label"
            onChange={handleChange}
            value={permission}
            defaultValue={row?.permission === null ? 1 : 4}
            sx={{ minWidth: 150 }}
          >
            <MenuItem value={4}>{t("Edit-v2")}</MenuItem>
            <MenuItem value={1}>{t("No Edit-v2")}</MenuItem>
          </Select>
        </FormControl>
        <ChangeParametersModal
          setOpenSuccess={setOpenSuccess}
          setOpenError={setOpenError}
          openSuccess={openSuccess}
          openError={openError}
          errorMessage={t("Message Error Change Permission")}
          successMessage={t("Message Success Change Permission")}
        />
      </TableCell>
    </>
  );
}

export default EditPermissionSpecialSelect;
