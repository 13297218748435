import { NavItemsType } from "types/typesTheme";
import { routes } from "routing/routes";
import { useTranslation } from "react-i18next";
import { Role } from "helpers/Global";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useSettings } from "hooks/useSettings";

const NavLinksList = () => {
  const { t } = useTranslation();
  const [iframePollIsOpen, setIframePollIsOpen] = useState(false);
  const { settings } = useSettings();
  function toggleNav() {
    document.body.classList.toggle("noScroll");
  }
  function toggleNavRemove() {
    document.body.classList.remove("noScroll");
  }
  const navigation = (): NavItemsType => {
    return [
      {
        title: t("Actives Sessions"),
        path: routes.ACTIVE_SESSIONS.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Users"),
      },
      {
        title: t("Edit Permissions"),
        path: routes.EDIT_PERMISSIONS.path,
        requireRole: [Role.ROLE_SUPER_USER],
        navSection: t("Users"),
      },
      {
        title: t("Users"),
        path: routes.USERS.path,
        requireRole: [Role.ROLE_ADMIN, Role.ROLE_USER],
        navSection: t("Users"),
      },
      {
        title: t("Add User"),
        path: routes.ADD_USER.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Users"),
      },
      {
        title: t("Junction Profiles"),
        path: routes.JUNCTION_PROFILES.path,
        requireRole: [Role.ROLE_SUPER_USER],
        navSection: t("Users"),
      },
      {
        title: t("Checking Of Profiles"),
        path: routes.CHECKING_OF_PROFILES.path,
        requireRole: [Role.ROLE_ADMIN, Role.ROLE_SII_EMPLOYEE],
        navSection: t("Users"),
      },
      {
        title: t("Membership Verification"),
        path: routes.MEMBERSHIP_VERIFICATION.path,
        requireRole: [Role.ROLE_ADMIN, Role.ROLE_PARTNER],
        navSection: t("Users"),
      },
      {
        title: t("Verification History"),
        path: routes.VERIFICATION_HISTORY.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Management Reports"),
      },
      {
        title: t("Login History"),
        path: routes.LOGIN_HISTORY.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Management Reports"),
      },
      {
        title: t("Contact Types"),
        path: routes.CONTACT_TYPES.path,
        requireRole: [Role.ROLE_SUPER_USER],
        navSection: t("Configuration"),
      },
      {
        title: t("Verification Statement"),
        path: routes.VERIFICATION_STATEMENT.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Users"),
      },
      {
        title: t("Change Password"),
        path: routes.CHANGE_PASSWORD.path,
        requireRole: [
          Role.ROLE_ADMIN,
          Role.ROLE_REDACTOR_LEVEL,
          Role.ROLE_SII_EMPLOYEE,
        ],
        navSection: t("Users"),
      },
      {
        title: t("Edit Subscriptions"),
        path: routes.EDIT_SUBSCRIPTIONS.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Subscriptions"),
      },
      {
        title: t("Discount Codes"),
        path: routes.DISCOUNT_CODES.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Configuration"),
      },
      {
        title: t("Forms-Answers"),
        path: routes.FORM_ANSWERS.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Forms/Polls"),
      },
      {
        title: t("Add/Edit Polls"),
        path: routes.ADD_POLL.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Forms/Polls"),
        navContent: () => {
          return (
            <div>
              <button
                style={{ position: "static" }}
                className="nav-btn"
                onClick={() => {
                  setIframePollIsOpen(true);
                  toggleNav();
                }}
              >
                <a
                  style={{
                    color:
                      settings.mode === "light" ? "rgb(38,37,37)" : "#e7e3fcde",
                  }}
                  className="nav-btn-link"
                  href="#open-modal"
                >
                  {t("Add/Edit Polls")}
                </a>
              </button>
              <div id="open-modal" className="modal-window-wrapper">
                <div
                  style={{
                    backgroundColor:
                      settings.mode === "light"
                        ? "rgb(255,255,255)"
                        : "rgba(37,34,34,0.1)",
                  }}
                  className="modal-content"
                >
                  <button
                    className="btn"
                    onClick={() => {
                      setIframePollIsOpen(false);
                      toggleNavRemove();
                    }}
                  >
                    <a href="#" title="Close" className="modal-close">
                      <CloseIcon />
                    </a>
                  </button>
                  {iframePollIsOpen && (
                    <iframe
                      className="iframe-poll"
                      src={routes.ADD_POLL.path}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        title: t("Add/Edit Forms"),
        path: routes.EDIT_FORMS.path,
        requireRole: [Role.ROLE_ADMIN],
        navSection: t("Forms/Polls"),
        navContent: () => {
          return (
            <div>
              <button className="nav-btn" onClick={() => toggleNav()}>
                <a
                  style={{
                    color:
                      settings.mode === "light" ? "rgb(38,37,37)" : "#e7e3fcde",
                  }}
                  className="nav-btn-link"
                  href="#edit-forms"
                >
                  {t("Add/Edit Forms")}
                </a>
              </button>
              <div id="edit-forms" className="modal-window-wrapper">
                <div className="modal-content-form">
                  <button className="btn" onClick={() => toggleNavRemove()}>
                    <a href="#" title="Close" className="modal-close">
                      <CloseIcon />
                    </a>
                  </button>
                  <iframe
                    className="iframe-form"
                    src={routes.EDIT_FORMS.path}
                  />
                </div>
              </div>
            </div>
          );
        },
      },
    ];
  };
  return navigation();
};
export default NavLinksList;
