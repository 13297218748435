import React, { useState } from "react";
import TableBasic from "components/TableBasic/TableBasic";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { operations } from "schema/schema";
import useGetCrmsQuery from "hooks/useGetCrmsQuery";
import dayjs from "dayjs";
import { formatValueDateTime } from "helpers/Global";

interface UserHistoryTypes {
  userInformationData:
    | operations["api_apiv1users_id_get"]["responses"]["200"]["content"]["application/ld+json"]
    | undefined;
}
function History({ userInformationData }: UserHistoryTypes) {
  const { t } = useTranslation();
  const userId = String(userInformationData?.id);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [historyEnabled, setHistoryEnabled] = useState(true);

  const { data: history, isLoading: historyIsLoading } = useGetCrmsQuery(
    {
      query: {
        page: pageNumber,
        itemsPerPage: itemsPerPage,
        toUser: userId,
        "notType[]": ["30"],
        "order[createdAt]": "desc",
      },
    },
    {
      enabled: historyEnabled,
      onSettled: () => {
        setHistoryEnabled(false);
      },
    }
  );

  const columns: GridColDef[] = [
    {
      flex: 0.1,
      field: "createdAt",
      minWidth: 150,
      headerName: t("Date") as string,
      valueFormatter: (params) =>
        params.value ? dayjs(params.value).format(formatValueDateTime) : "",
    },
    {
      flex: 0.1,
      field: "typeCrm",
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value == null ? "-" : params.value;
      },
      headerName: t("Type") as string,
    },
    {
      flex: 0.1,
      field: "content",
      minWidth: 150,
      headerName: t("Comment") as string,
    },
    {
      flex: 0.1,
      field: "author",
      valueFormatter: (params) => {
        return params.value == null ? "System" : params.value;
      },
      minWidth: 80,
      headerName: t("Author") as string,
    },
  ];
  return (
    <Grid item xs={12}>
      <TableBasic
        paginationMode="server"
        columns={columns}
        loading={historyIsLoading}
        rows={history?.["hydra:member"]}
        title={t("History")}
        totalItems={history?.["hydra:totalItems"]}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        queryFunction={setHistoryEnabled}
      />
    </Grid>
  );
}

export default History;
