import React from "react";
import { useTranslation } from "react-i18next";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { map } from "lodash";
import { makeStyles } from "@mui/styles";
import RowValuesGenerate from "components/PageVerificationStatement/RowValuesGenerate";
import { sortedArrayTypes } from "types/types";
import { useSettings } from "hooks/useSettings";

export interface Partner {
  id: number;
  name: string;
  data: {
    month: number;
    resultValues: {
      status: "success" | "error";
      totalItems: number;
      values: {
        result: string;
        value: number;
      }[];
    }[];
  }[];
}

export const useStyles = makeStyles(() => ({
  tableRowMain: {
    backgroundColor: "rgba(167,227,143,0.3)",
  },
  tableRowMainDark: {
    backgroundColor: "rgba(180,225,165,0.15)",
  },
}));

function VerificationStatementRow({ partners, index }: any) {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { settings } = useSettings();
  const sortData = partners.data.sort(
    (a: { month: number }, b: { month: number }) => a.month - b.month
  );
  const sortedAndFilledArray: sortedArrayTypes[] | null | undefined = [];
  let previousMonth = 0;
  for (const item of sortData) {
    const currentMonth = item.month;

    if (currentMonth - previousMonth > 1) {
      for (let i = previousMonth + 1; i < currentMonth; i++) {
        sortedAndFilledArray.push({ month: i, resultValues: <TableCell /> });
      }
    }

    sortedAndFilledArray.push(item);
    previousMonth = currentMonth;
  }
  for (let i = previousMonth + 1; i <= 12; i++) {
    sortedAndFilledArray.push({ month: i, resultValues: <TableCell /> });
  }

  return (
    <>
      <TableRow
        className={
          (index as unknown as number) % 2 === 0
            ? classes.tableRowMain
            : classes.tableRowMainDark
        }
      >
        <TableCell
          className={"table-cell-custom"}
          rowSpan={
            openSuccess && openError ? 8 : openSuccess ? 4 : openError ? 6 : 2
          }
        >
          {partners.name}
        </TableCell>
        <TableCell className={"table-cell-custom collapse-table-cell-custom"}>
          <IconButton
            className={"icon-button-custom"}
            size="small"
            onClick={() => setOpenSuccess(!openSuccess)}
          >
            {openSuccess ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {t("Successful")}
          </IconButton>
        </TableCell>

        {map(sortedAndFilledArray, (months, index) => (
          <TableCell
            className={"table-cell-custom"}
            key={index}
            sx={{ textAlign: "-webkit-center", fontWeight: "bold" }}
            align="center"
          >
            {map(months?.resultValues, (resVal) =>
              resVal?.status === "success" ? resVal?.totalItems : ""
            )}
          </TableCell>
        ))}
        <TableCell
          className={"table-cell-custom"}
          sx={{ textAlign: "-webkit-center", fontWeight: "bold" }}
        >
          {partners?.detailsResultsForPartners[0].success[0].successTotal}
        </TableCell>
      </TableRow>

      {openSuccess &&
        RowValuesGenerate(
          "success",
          0,
          sortedAndFilledArray,
          partners,
          "partnerRow"
        )}
      <TableRow
        sx={{
          backgroundColor:
            settings.mode === "light"
              ? "rgba(210,208,208,0.63)"
              : "rgba(45,43,43,0.63)",
        }}
      >
        <TableCell className={"table-cell-custom collapse-table-cell-custom"}>
          <IconButton
            className={"icon-button-custom"}
            size="small"
            onClick={() => setOpenError(!openError)}
          >
            {openError ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            {t("Failed")}
          </IconButton>
        </TableCell>

        {map(sortedAndFilledArray, (months, index) => (
          <TableCell
            className={"table-cell-custom"}
            key={index}
            sx={{ textAlign: "-webkit-center" }}
            align="center"
          >
            {map(months?.resultValues, (resVal) =>
              resVal?.status === "error" ? resVal?.totalItems : ""
            )}
          </TableCell>
        ))}
        <TableCell
          className={"table-cell-custom"}
          sx={{ textAlign: "-webkit-center", fontWeight: "bold" }}
        >
          {partners?.detailsResultsForPartners[0].error[0].errorTotal}
        </TableCell>
      </TableRow>
      {openError &&
        RowValuesGenerate(
          "error",
          1,
          sortedAndFilledArray,
          partners,
          "partnerRow"
        )}
    </>
  );
}
export default VerificationStatementRow;
