import React from "react";
import { useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import { Box, Divider, Link, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import Roles from "components/PageUsers/Search/Roles";
import UsersAndMembers from "components/PageUsers/UsersAndMembers/UsersAndMembers";
import UsersList from "components/PageUsers/UsersList/UsersList";
import Departments from "components/PageUsers/Departments/Departments";
import UserActivity from "components/PageUsers/UserActivity/UserActivity";
import CRMFields from "components/PageUsers/CRMFields/CRMFields";
import Search from "components/PageUsers/Search/Search";
import useUserList from "./hooks/useUserList";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Loader } from "components/Loader/Loader";
import { useSettings } from "hooks/useSettings";
import CustomButton from "components/Buttons/CustomButton";
import UseHasAccess from "hooks/useHasAccess";
import { Role } from "helpers/Global";

export interface FormInputs {
  searchValue: string;
  userParameters: string;
  roleName: string[];
  departmentsName: string[];
  crmFilledAllOrOneFields: string[];
  crmBlankAllOrOneNoneFields: string[];
  joinMethodAndOrCrm: string;
  crmFilledFields: string[];
  crmBlankFields: string[];
  crmContainTextField: string[];
  containTextField: string;
  crmDateFrom: any;
  crmDateTo: any;
  activityLoggedOrNotLoggedProperties: string;
  activityLoggedOrNotLoggedChecked: boolean;
  activityLoggedOrNotLoggedDaysField: number;
  activityRegisteredOrNotRegisteredProperties: string;
  activityRegisteredOrNotRegisteredChecked: boolean;
  activityRegisteredOrNotRegisteredDaysField: number;
  usersAndMembersIsPaidUsers: boolean;
  usersAndMembersIsPaidUsersValue: string;
  usersAndMembersIsActiveMember: boolean;
  usersAndMembersIsActiveMemberValue: string;
  usersActiveAndNotDeleted: boolean;
  usersAndMembersShowRegisteredUsers: boolean;
  usersAndMembersShowRegisteredUsersValue: number;
  usersDepartmentsValue: string[];
}

export enum LoggedState {
  Logged = "lastDaysLoggedWithin",
  NotLogged = "lastDaysNotLoggedWithin",
}
export enum RegisteredState {
  Registered = "lastDaysRegisteredWithin",
  NotRegistered = "lastDaysNotRegisteredWithin",
}

const Users = () => {
  const { t } = useTranslation();
  const hasAccess = UseHasAccess([Role.ROLE_SUPER_USER]).length > 0;
  const { control, handleSubmit, reset } = useForm<FormInputs>({
    mode: "onBlur",
    defaultValues: {
      searchValue: "",
      userParameters: "searchAll",
      roleName: [],
      departmentsName: [],
      crmFilledAllOrOneFields: ["allSelected"],
      crmBlankAllOrOneNoneFields: ["noneOfAllSelected"],
      joinMethodAndOrCrm: "and",
      crmFilledFields: [],
      crmBlankFields: [],
      crmContainTextField: ["crmContentContains"],
      containTextField: "",
      crmDateFrom: null,
      crmDateTo: null,
      activityLoggedOrNotLoggedProperties: "lastDaysLoggedWithin",
      activityLoggedOrNotLoggedChecked: false,
      activityLoggedOrNotLoggedDaysField: 14,
      activityRegisteredOrNotRegisteredProperties: "lastDaysRegisteredWithin",
      activityRegisteredOrNotRegisteredChecked: false,
      activityRegisteredOrNotRegisteredDaysField: 14,
      usersAndMembersIsPaidUsers: false,
      usersAndMembersIsPaidUsersValue: "paid",
      usersActiveAndNotDeleted: true,
      usersAndMembersIsActiveMember: false,
      usersAndMembersIsActiveMemberValue: "active",
      usersAndMembersShowRegisteredUsers: false,
      usersAndMembersShowRegisteredUsersValue: 14,
      usersDepartmentsValue: [],
    },
  });
  const {
    data,
    isLoading,
    setFilterEnabled,
    setCSVEnabled,
    sortOptions,
    csvDataLoading,
    setSortOptions,
    csvData,
    pageNumber,
    setPageNumber,
    itemsPerPage,
    setItemsPerPage,
    totalItems,
  } = useUserList({ control });
  const theme = useTheme();
  const { settings } = useSettings();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onSubmit = () => {
    setFilterEnabled(true);
  };
  const GetCsv = () => {
    setCSVEnabled(true);
  };
  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title={t("Users")} />
        <Divider />
        <Grid
          container
          spacing={6}
          item
          sx={{ mt: 4, justifyContent: onlyMediumScreen ? "center" : "start" }}
        >
          <Grid
            item
            xl={5}
            sx={{
              width: onlySmallScreen ? "100%" : "unset",
              pl: onlySmallScreen ? 0 : 3,
            }}
          >
            <Grid
              sx={{ mb: 5, ml: onlySmallScreen ? 0 : 5 }}
              item
              xl={12}
              className="users-filters-search"
            >
              <Card>
                <Typography sx={{ ml: 5, mt: 5 }}>
                  {t("Search By Name / Role")}
                </Typography>
                <Box
                  sx={{
                    maxWidth: 600,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: onlySmallScreen ? "center" : "unset",
                    m: 5,
                  }}
                >
                  <Search control={control} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    my: 5,
                    mx: onlySmallScreen ? 0 : 5,
                    display: "flex",
                    justifyContent: onlySmallScreen ? "center" : "unset",
                  }}
                >
                  <Roles control={control} />
                </Box>
              </Card>
            </Grid>
            <Grid
              sx={{ ml: onlySmallScreen ? 0 : 5 }}
              item
              xl={12}
              className="users-filters-department-search"
            >
              <Card>
                <Typography sx={{ ml: 5, mt: 5 }}>{t("Department")}</Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: onlySmallScreen ? 0 : 5,
                    justifyContent: onlySmallScreen ? "center" : "unset",
                    my: 5,
                  }}
                >
                  <Departments control={control} />
                </Box>
              </Card>
            </Grid>
          </Grid>
          <Grid
            sx={{
              ml: onlySmallScreen ? 0 : 5,
              width: onlySmallScreen ? "100%" : "unset",
            }}
            item
            xl={6}
            className="crm-section"
          >
            <Card sx={{ paddingBottom: 3 }}>
              <Typography sx={{ ml: 5, mt: 5 }}>{t("CRM Fields")}</Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  mx: onlySmallScreen ? 0 : 5,
                  justifyContent: onlySmallScreen ? "center" : "unset",
                  my: 5,
                }}
              >
                <CRMFields control={control} />
              </Box>
            </Card>
          </Grid>
          <Grid
            sx={{
              ml: onlySmallScreen ? 0 : 5,
              width: onlySmallScreen ? "100%" : "unset",
            }}
            item
            xl={6}
            className="users-activity-section"
          >
            <Card>
              <Typography sx={{ ml: 5, mt: 5 }}>{t("Activity")}</Typography>
              <Box
                sx={{
                  marginLeft: onlySmallScreen ? 0 : 5,
                  width: onlySmallScreen ? "100%" : "unset",
                  my: 5,
                }}
              >
                <UserActivity control={control} />
              </Box>
            </Card>
          </Grid>
          <Grid
            sx={{
              ml: onlySmallScreen ? 0 : 5,
              width: onlySmallScreen ? "100%" : "unset",
            }}
            item
            xl={7}
            className="users-and-members-section"
          >
            <Card>
              <Typography sx={{ ml: 5, mt: 5 }}>
                {t("Users")} / {t("Members")}
              </Typography>
              <Box
                sx={{
                  marginLeft: onlySmallScreen ? 0 : 5,
                  width: onlySmallScreen ? "100%" : "unset",
                  my: 5,
                }}
              >
                <UsersAndMembers resetForm={reset} control={control} />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} className="users-table">
            <Box
              sx={{
                m: 5,
              }}
            >
              <UsersList
                isLoading={isLoading}
                rows={data}
                sortOptions={sortOptions}
                setSortOptions={setSortOptions}
                pageNumber={pageNumber}
                setFilterEnabled={setFilterEnabled}
                setPageNumber={setPageNumber}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalItems={totalItems}
              />
            </Box>
            {csvDataLoading && <Loader />}
            {hasAccess && (
              <CustomButton
                text={t("Generate CSV")}
                style={{ mx: 5, mb: 5 }}
                onClickFunction={() => GetCsv()}
              />
            )}
            {csvData && !csvDataLoading && (
              <Button
                color="inherit"
                variant="contained"
                className="button-custom-styles"
                sx={{ mx: 5, mb: 5 }}
              >
                <Link
                  sx={{
                    textDecoration: "none",
                    color: "black",
                    "&:hover": { color: "inherit" },
                  }}
                  href={encodeURI(`data:text/csv;charset=utf-8,${csvData}`)}
                  download="users.csv"
                >
                  {t("Download")}
                </Link>
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Card>
  );
};

export default Users;
