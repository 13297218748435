import React, { useState } from "react";
import { Box, Card, CardHeader, Divider, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { useSettings } from "hooks/useSettings";
import { useForm, useWatch } from "react-hook-form";
import EditSingleForm from "components/PageEditForms/EditSingleForm/EditSingleForm";
import useGetFormsQuery from "hooks/useGetFormsQuery";
import useGetFormsExtendedQuery from "hooks/useGetFormsExtendedQuery";
import { Loader } from "components/Loader/Loader";
import SelectAutocomplete from "components/PageEditForms/SelectAutocomplete";

export interface FormInputs {
  form?: any;
  formId: number | null;
  title: string;
  messageAfterFilled: any;
  allowToFilledForm: boolean;
  saveFormToDatabase: boolean;
  sendEmail: boolean;
  emailAddress?: string | undefined;
  printVersion: boolean;
  isRecaptcha: boolean;
  onlyForLogged: boolean;
  pointsForFilled: number | null;
  isPaid: boolean;
  priceForExtendedMembers?: number | null;
  priceForOthers?: number | null;
  admissibleAdvance?: number | null;
  fillingLimitation: boolean;
  byAddressForDays: number | null;
  byCookiesForDays: number | null;
  isMessageForUser: boolean;
  messageForUser: string | null;
  conversionTracking: boolean;
  bodyCode: string | null;
  headCode: string | null;
  questions:
    | {
        id: number | undefined;
        dirty: boolean;
        text: string;
        sequence: number;
        typePosition: number;
        required: boolean;
        status: number;
        answerVariants: {
          answerText: string;
          description: string | null;
          id: number | undefined;
          sequence: number;
          type: number;
          required: boolean;
          status: number;
          location: number;
          rowColumn: number | null;
          validate: string | null;
        }[];
      }[]
    | [];
}

function EditForms() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { settings } = useSettings();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFocusedSelect, setIsFocusedSelect] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const matchResult = inputValue.match(/^(.*?)\s?\( ID:/);
  const { data: formsData, isLoading: formsDataLoading } = useGetFormsQuery(
    {
      query: {
        pagination: false,
        text: matchResult ? matchResult[1] : undefined,
      },
    },
    {
      enabled: inputValue === "" || inputValue.length > 2,
    }
  );
  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      formId: 0,
    },
  });
  const formId = useWatch({ control: control, name: "formId" });
  const { data: singleFormData, isLoading: singeFormDataLoading } =
    useGetFormsExtendedQuery(
      JSON.stringify(formId),
      { query: {} },
      {
        enabled: !!formId && formId !== 0,
        cacheTime: 0,
      }
    );
  return (
    <Card
      sx={{
        backgroundColor: settings.mode === "light" ? "inherit" : "#28243D",
        mb: onlySmallScreen ? "150px" : "inherit",
      }}
    >
      <CardHeader title={t("Add/Edit Forms")} />
      <Divider />
      <Grid
        container
        spacing={6}
        item
        sx={{
          m: 0,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            p: 5,
            width: "100%",
            overflow: onlyMediumScreen ? "hidden" : "inherit",
          }}
        >
          <form>
            {(formsDataLoading || singeFormDataLoading) && <Loader />}
            <SelectAutocomplete
              inputValue={inputValue}
              control={control}
              setValue={setValue}
              setInputValue={setInputValue}
              formsData={formsData}
              isFocusedSelect={isFocusedSelect}
              setIsFocusedSelect={setIsFocusedSelect}
            />
          </form>
          <EditSingleForm
            inputValue={inputValue}
            singleFormData={singleFormData}
            setValue={setValue}
          />
        </Box>
      </Grid>
    </Card>
  );
}

export default EditForms;
