import React from "react";
import { Control, Controller } from "react-hook-form";
import { Autocomplete, useMediaQuery } from "@mui/material";
import { map } from "lodash";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

interface Props {
  inputValue: string;
  control: Control<any>;
  setValue: any;
  setInputValue: (value: string) => void;
  formsData: any;
  isFocusedSelect: boolean;
  setIsFocusedSelect: (value: boolean) => void;
}

function SelectAutocomplete({
  inputValue,
  control,
  setInputValue,
  formsData,
  setValue,
  isFocusedSelect,
  setIsFocusedSelect,
}: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleFormIdChange = (selectedValue: any) => {
    const selectedForm = formsData?.["hydra:member"].find(
      (form: { text: any }) =>
        form.text === selectedValue.match(/^(.*?)\s?\( ID:/)[1]
    );
    const selectedFormId = selectedForm ? selectedForm.id : null;
    setValue("formId", selectedFormId);
  };

  return (
    <Controller
      name="formId"
      control={control}
      render={({ field }) => (
        <Autocomplete
          {...field}
          noOptionsText={
            inputValue.length < 3 ? t("Enter At Least 3 Characters") : t("None")
          }
          sx={{
            width: onlyMediumScreen ? "100%" : "700px",
            mb: 5,
            mt: 2,
          }}
          onInputChange={(_, value) => setInputValue(value)}
          value={inputValue}
          open={(inputValue.length > 0 || inputValue === "") && isFocusedSelect}
          onFocus={() => setIsFocusedSelect(true)}
          onOpen={() => setIsFocusedSelect(true)}
          onClose={() => setIsFocusedSelect(false)}
          isOptionEqualToValue={(option: any, value) =>
            option.text === value.text
          }
          options={
            map(
              formsData?.["hydra:member"],
              (formValue: any) => `${formValue.text} ( ID: ${formValue.id})`
            ) || []
          }
          onChange={(_, selectedValue) => handleFormIdChange(selectedValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("Available Forms")}
              variant="outlined"
            />
          )}
        />
      )}
    />
  );
}

export default SelectAutocomplete;
