import React from "react";
import { GridSortModel } from "@mui/x-data-grid";
import { concat, filter, find, map } from "lodash";

export function headerSortTable(
  sortOptions: GridSortModel,
  setSortOptions: React.Dispatch<React.SetStateAction<GridSortModel>>,
  field: string
) {
  const sortField = find(sortOptions, { field });
  if (sortField) {
    if (sortField?.sort === "desc") {
      setSortOptions(filter(sortOptions, (val) => val.field !== field));
    } else {
      setSortOptions(
        map(sortOptions, (val) =>
          val.field === field ? { ...val, sort: "desc" } : val
        )
      );
    }
  } else {
    setSortOptions(concat(sortOptions, { field, sort: "asc" }));
  }
}
