import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  FormControlLabel,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { Controller, useForm, useWatch } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";
import usePostTransactionRefundMutation from "hooks/usePostTransactionRefundMutation";
import { Loader } from "components/Loader/Loader";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";

interface RefundTransactionModalTypes {
  showTransactionModal: boolean;
  setShowTransactionModal: (val: boolean) => void;
  idTransaction: string;
}

interface FormInputs {
  amount: number;
  refundWholeTransaction: boolean;
  refundWithP24: boolean;
}

function RefundTransactionModal({
  showTransactionModal,
  setShowTransactionModal,
  idTransaction,
}: RefundTransactionModalTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [openSuccess, setOpenSuccess] = useState(false);
  const [message, setMessage] = useState<string | null>("");
  const [openError, setOpenError] = useState(false);
  const { mutate: transactionRefund, isLoading: transactionRefundLoading } =
    usePostTransactionRefundMutation();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { control: refundPaymentControl, handleSubmit } = useForm<FormInputs>({
    defaultValues: {
      refundWithP24: false,
      refundWholeTransaction: false,
    },
  });
  const onSubmit = ({
    refundWithP24,
    amount,
    refundWholeTransaction,
  }: FormInputs) => {
    transactionRefund(
      {
        payload: {
          refund_p24_payment: refundWithP24,
          amount_in_cents: refundWholeTransaction ? null : amount * 100,
          p24_transaction: idTransaction,
        },
      },
      {
        onSuccess: () => {
          setOpenSuccess(true);
          setShowTransactionModal(false);
        },
        onError: (error: any) => {
          setOpenSuccess(false);
          setOpenError(true);
          if (error.response) {
            error.response.data["hydra:description"].substring(0, 29) ===
            "Available amount to refund is"
              ? setMessage(
                  t("Refund Amount Is Higher Than The Transaction Amount")
                )
              : setMessage(error.response.data["hydra:description"]);
          }
        },
      }
    );
  };
  const { refundWholeTransaction } = useWatch({
    control: refundPaymentControl,
  });
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {transactionRefundLoading && <Loader />}
      <Dialog
        open={showTransactionModal}
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.customColors.modalBgc,
          },
        }}
      >
        <Box
          className="array-modal"
          sx={{
            width: onlyMediumScreen ? "90vw" : "70vw",
            minHeight: 150,
            px: onlySmallScreen ? 5 : "unset",
          }}
        >
          <Card
            sx={{
              minHeight: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingRight: onlySmallScreen ? "unset" : "50px",
              flexWrap: "wrap",
            }}
          >
            <FormControl sx={{ mx: 5 }}>
              <Controller
                control={refundPaymentControl}
                name="amount"
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    onChange={onChange}
                    disabled={refundWholeTransaction}
                    variant="standard"
                    type="number"
                    sx={{ width: 70, my: 2, mx: 5 }}
                    label={t("Amount")}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">zł</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl sx={{ mx: 5 }}>
              <Controller
                control={refundPaymentControl}
                name="refundWholeTransaction"
                render={({ field: { onChange } }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox onChange={onChange} />}
                      label={t("Refund Whole Transaction")}
                    />
                  </>
                )}
              />
            </FormControl>
            <FormControl sx={{ mx: 5 }}>
              <Controller
                control={refundPaymentControl}
                name="refundWithP24"
                render={({ field: { onChange } }) => (
                  <>
                    <FormControlLabel
                      control={<Checkbox onChange={onChange} />}
                      label={t("Refund With P24")}
                    />
                  </>
                )}
              />
            </FormControl>
            <Button
              size="large"
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="secondary"
              sx={{ m: 2 }}
            >
              {t("Send")}
            </Button>
          </Card>
          <Button
            className="array-close-button"
            onClick={() => setShowTransactionModal(false)}
          >
            <CloseIcon />
          </Button>
        </Box>
      </Dialog>
      <ChangeParametersModal
        openSuccess={openSuccess}
        openError={openError}
        setOpenSuccess={setOpenSuccess}
        setOpenError={setOpenError}
        errorMessage={`${t("Message Error Refund Transaction")}. ${t(
          "Error Message"
        )}: ${message}`}
        successMessage={t("Message Success Refund Transaction")}
      />
    </form>
  );
}
export default RefundTransactionModal;
