import React, { useState, MouseEvent } from "react";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DotsVertical from "mdi-material-ui/DotsVertical";
import IconButton from "@mui/material/IconButton";

interface ActionsButtonTransactionsTypes {
  id: string;
  setShowTransactionModal: (val: boolean) => void;
  setIdTransaction: (id: string) => void;
}

const ActionsButtonTransactions = ({
  id,
  setShowTransactionModal,
  setIdTransaction,
}: ActionsButtonTransactionsTypes) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const rowOptionsOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <DotsVertical />
      </IconButton>
      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={rowOptionsOpen}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: { minWidth: "8rem" } }}
      >
        <MenuItem
          onClick={() => {
            setIdTransaction(id);
            setShowTransactionModal(true);
          }}
        >
          <PaymentsOutlinedIcon fontSize="small" sx={{ mr: 2 }} />
          {t("Refund Transaction")}
        </MenuItem>
      </Menu>
    </>
  );
};
export default ActionsButtonTransactions;
