import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  FormControl,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import ChangeParametersModal from "components/ChangeParametersModal/ChangeParametersModal";
import { useStyles } from "style/muiStyles";
import FieldArray from "components/PageEditForms/EditSingleForm/EditFormQuestion/EditFormQuestion";
import usePutFormMutation from "hooks/usePutFormMutation";
import usePostFormMutation from "hooks/usePostFormMutation";
import { Loader } from "components/Loader/Loader";
import InputCheckBoxValue from "components/PageEditForms/EditSingleForm/Components/InputCheckBoxValue";
import { InputTextFieldValue } from "components/PageEditForms/EditSingleForm/Components/InputTextFieldValue";
import { FormInputs } from "pages/EditForms/EditForms";
import ReactQuillEditor from "components/PageEditForms/EditSingleForm/Components/ReactQuillEditor";
import { components } from "schema/schema";
import CustomButton from "components/Buttons/CustomButton";

export interface FormDataTypes {
  setValue: (name: any, value?: unknown, config?: Object) => void;
  singleFormData?: components["schemas"]["Form.jsonld-form.extended"];
  inputValue?: string;
}

function EditSingleForm({
  singleFormData: singleFormData,
  setValue,
  inputValue,
}: FormDataTypes) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const methods = useForm<FormInputs>();
  const sendEmail = useWatch({
    control: methods.control,
    name: "sendEmail",
  });
  const onlyForLogged = useWatch({
    control: methods.control,
    name: "onlyForLogged",
  });
  const isPaid = useWatch({
    control: methods.control,
    name: "isPaid",
  });
  const fillingLimitation = useWatch({
    control: methods.control,
    name: "fillingLimitation",
  });
  const isMessageForUser = useWatch({
    control: methods.control,
    name: "isMessageForUser",
  });
  const conversionTracking = useWatch({
    control: methods.control,
    name: "conversionTracking",
  });
  const intParseFn = (value: number | null) => {
    return value ? parseInt(String(value)) : undefined;
  };
  const { mutate: updateForm, isLoading: updateFormLoading } =
    usePutFormMutation();
  const { mutate: addForm, isLoading: addFormLoading } = usePostFormMutation();
  const onSubmit = ({
    title,
    allowToFilledForm,
    saveFormToDatabase,
    sendEmail,
    emailAddress,
    printVersion,
    messageAfterFilled,
    isRecaptcha,
    onlyForLogged,
    pointsForFilled,
    isPaid,
    priceForExtendedMembers,
    priceForOthers,
    admissibleAdvance,
    messageForUser,
    fillingLimitation,
    byAddressForDays,
    byCookiesForDays,
    isMessageForUser,
    conversionTracking,
    headCode,
    bodyCode,
    questions,
  }: FormInputs) => {
    const payloadValues = {
      text: title,
      fillForm: allowToFilledForm,
      saveFormToDB: saveFormToDatabase,
      sendEmail: sendEmail,
      sendEmailConfirm: emailAddress,
      versionToPrint: printVersion,
      reCaptchaSecurity: isRecaptcha,
      availableFormToLoggedUsers: onlyForLogged,
      fulfillmentPoints: intParseFn(pointsForFilled),
      paidForm: isPaid,
      priceForExtendedMembers:
        priceForExtendedMembers !== null &&
        priceForExtendedMembers !== undefined
          ? priceForExtendedMembers * 100
          : undefined,
      priceForOtherMembers:
        priceForOthers !== undefined && priceForOthers !== null
          ? priceForOthers * 100
          : undefined,
      advanceAllowed:
        admissibleAdvance !== undefined && admissibleAdvance !== null
          ? admissibleAdvance * 100
          : undefined,
      filledLimit: fillingLimitation,
      ipLimit: intParseFn(byAddressForDays),
      cookiesLimit: intParseFn(byCookiesForDays),
      typePositionValue: messageAfterFilled,
      messageForUser: isMessageForUser,
      messageContent: messageForUser,
      conversionTracking: conversionTracking,
      headCode: headCode,
      bodyCode: bodyCode,
      questions: questions,
    };
    singleFormData && singleFormData.id !== 0
      ? updateForm(
          {
            formId: singleFormData?.id,
            payload: payloadValues,
          },
          {
            onSuccess: () => {
              setOpenSuccess(true);
              setOpenError(false);
            },
            onError: () => {
              setOpenError(true);
              setOpenSuccess(false);
            },
          }
        )
      : title &&
        title?.length > 2 &&
        addForm(
          {
            payload: payloadValues,
          },
          {
            onSuccess: () => {
              setOpenSuccess(true);
              setOpenError(false);
              methods.reset();
            },
            onError: () => {
              setOpenError(true);
              setOpenSuccess(false);
            },
          }
        );
  };
  const resetFormFunction = () =>
    methods.reset({
      title: "",
      messageAfterFilled: "",
      allowToFilledForm: false,
      saveFormToDatabase: false,
      sendEmail: false,
      emailAddress: "",
      printVersion: false,
      isRecaptcha: false,
      onlyForLogged: false,
      pointsForFilled: undefined,
      isPaid: false,
      priceForExtendedMembers: undefined,
      priceForOthers: undefined,
      admissibleAdvance: undefined,
      fillingLimitation: false,
      byAddressForDays: undefined,
      byCookiesForDays: undefined,
      isMessageForUser: false,
      messageForUser: "",
      conversionTracking: false,
      headCode: "",
      bodyCode: "",
      questions: [],
    });
  useEffect(() => {
    const handleClearButtonClick = () => {
      resetFormFunction();
    };
    const autocompleteClearButtons = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    );
    Array.from(autocompleteClearButtons).forEach((button) => {
      button.addEventListener("click", handleClearButtonClick);
    });
    return () => {
      Array.from(autocompleteClearButtons).forEach((button) => {
        button.removeEventListener("click", handleClearButtonClick);
      });
    };
  }, [resetFormFunction]);
  useEffect(() => {
    methods.reset({
      title: singleFormData?.text,
      allowToFilledForm: singleFormData?.fillForm,
      saveFormToDatabase: singleFormData?.saveFormToDB,
      sendEmail: singleFormData?.sendEmail,
      messageAfterFilled: singleFormData?.typePositionValue,
      emailAddress: singleFormData?.sendEmailConfirm,
      printVersion: singleFormData?.versionToPrint,
      isRecaptcha: singleFormData?.reCaptchaSecurity,
      onlyForLogged: singleFormData?.availableFormToLoggedUsers,
      pointsForFilled: singleFormData?.fulfillmentPoints,
      isPaid: singleFormData?.paidForm,
      priceForExtendedMembers:
        typeof singleFormData?.priceForExtendedMembers === "number"
          ? singleFormData?.priceForExtendedMembers / 100
          : null,
      priceForOthers:
        typeof singleFormData?.priceForOtherMembers === "number"
          ? singleFormData?.priceForOtherMembers / 100
          : undefined,
      admissibleAdvance:
        typeof singleFormData?.advanceAllowed === "number"
          ? singleFormData?.advanceAllowed / 100
          : undefined,
      fillingLimitation: singleFormData?.filledLimit,
      byAddressForDays: singleFormData?.ipLimit,
      byCookiesForDays: singleFormData?.cookiesLimit,
      isMessageForUser: singleFormData?.messageForUser,
      messageForUser: singleFormData?.messageContent,
      conversionTracking: singleFormData?.conversionTracking,
      headCode: singleFormData?.headCode,
      bodyCode: singleFormData?.bodyCode,
      questions: singleFormData?.questions || [],
    });
  }, [singleFormData, methods.reset]);
  return (
    <div>
      {(updateFormLoading || addFormLoading) && <Loader />}
      <FormProvider {...methods}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FormControl
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: onlySmallScreen ? "block" : "flex",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  width: onlySmallScreen ? "unset" : "150px",
                  lineHeight: "42px",
                }}
              >
                {t("Title")}
              </Typography>
              <InputTextFieldValue
                name="title"
                style={{
                  ml: onlySmallScreen ? 0 : 1,
                  maxWidth: "600px",
                  width: onlySmallScreen ? "100%" : "80%",
                }}
                type="string"
              />
            </Box>
          </FormControl>
          <Box
            sx={{
              display: onlySmallScreen ? "block" : "flex",
              height: "auto",
              my: 5,
            }}
          >
            <Typography
              sx={{
                width: onlySmallScreen ? "unset" : "250px",
                lineHeight: "42px",
              }}
            >
              {t("Message After Filled")}
            </Typography>
            <Box sx={{ mx: onlySmallScreen ? 0 : 1, maxWidth: "550px" }}>
              <ReactQuillEditor name="messageAfterFilled" />
            </Box>
          </Box>
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("Results Handling")}
          </Typography>
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="allowToFilledForm"
              label={t("Allow To Filled Form")}
            />
          </FormControl>
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="saveFormToDatabase"
              label={t("Save Form To Database")}
            />
          </FormControl>
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="sendEmail"
              label={t("Send Email")}
            />
          </FormControl>
          {sendEmail && (
            <FormControl className={classes.formControlStyles}>
              <Typography
                sx={{
                  width: onlySmallScreen ? "unset" : "50px",
                  lineHeight: "42px",
                }}
              >
                {t("Email")}
              </Typography>
              <InputTextFieldValue
                name="emailAddress"
                style={{ ml: 1, width: "200px" }}
                type="string"
                maxLength={35}
              />
            </FormControl>
          )}
          <Typography
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("Form Options")}
          </Typography>
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="printVersion"
              label={t("Print Version")}
            />
          </FormControl>
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="isRecaptcha"
              label={t("Is Recaptcha")}
            />
          </FormControl>
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="onlyForLogged"
              label={t("Only For Logged")}
            />
          </FormControl>
          {onlyForLogged && (
            <FormControl className={classes.formControlStyles}>
              <Typography className="form-label-textField">
                {t("Points For Filled")}
              </Typography>
              <InputTextFieldValue
                name="pointsForFilled"
                style={{ width: "100px" }}
                type="number"
                maxLength={7}
              />
            </FormControl>
          )}
          <FormControl className="form-label">
            <InputCheckBoxValue isLabel name="isPaid" label={t("Is Paid")} />
          </FormControl>
          {isPaid && (
            <>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("Price For Sii Members")}
                </Typography>
                <InputTextFieldValue
                  name="priceForExtendedMembers"
                  style={{ width: "100px" }}
                  type="number"
                  maxLength={7}
                />
                <Typography className="form-label-textField" sx={{ pl: 1 }}>
                  PLN
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("Price For Others")}
                </Typography>
                <InputTextFieldValue
                  name="priceForOthers"
                  style={{ width: "100px" }}
                  type="number"
                  maxLength={7}
                />
                <Typography className="form-label-textField" sx={{ pl: 1 }}>
                  PLN
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("Admissible Advance")}
                </Typography>
                <InputTextFieldValue
                  name="admissibleAdvance"
                  style={{ width: "100px" }}
                  type="number"
                  maxLength={7}
                />
                <Typography className="form-label-textField" sx={{ pl: 1 }}>
                  PLN
                </Typography>
              </FormControl>
            </>
          )}
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="fillingLimitation"
              label={t("Filling Limitation")}
            />
          </FormControl>
          {fillingLimitation && (
            <>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("By Address For:")}
                </Typography>
                <InputTextFieldValue
                  name="byAddressForDays"
                  style={{ width: "100px" }}
                  type="number"
                  maxLength={4}
                />
                <Typography sx={{ pl: 2 }} className="form-label-textField">
                  {t("Days")}
                </Typography>
              </FormControl>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("By Cookies For:")}
                </Typography>
                <InputTextFieldValue
                  name="byCookiesForDays"
                  style={{ width: "100px" }}
                  type="number"
                  maxLength={4}
                />
                <Typography sx={{ pl: 2 }} className="form-label-textField">
                  {t("Days")}
                </Typography>
              </FormControl>
            </>
          )}
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="isMessageForUser"
              label={t("Message For User")}
            />
          </FormControl>
          {isMessageForUser && (
            <Box sx={{ mx: onlySmallScreen ? 0 : 1, maxWidth: "550px" }}>
              <ReactQuillEditor name="messageForUser" />
            </Box>
          )}
          <FormControl className="form-label">
            <InputCheckBoxValue
              isLabel
              name="conversionTracking"
              label={t("Conversion Tracking")}
            />
          </FormControl>
          {conversionTracking && (
            <>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("Paste Adwords Head Code")}
                </Typography>
                <InputTextFieldValue
                  name="headCode"
                  style={{ width: "300px" }}
                  type="string"
                />
              </FormControl>
              <FormControl className={classes.formControlStyles}>
                <Typography className="form-label-textField">
                  {t("Paste Adwords Body Code")}
                </Typography>
                <InputTextFieldValue
                  name="bodyCode"
                  style={{ width: "300px" }}
                  type="string"
                />
              </FormControl>
            </>
          )}
          {(!inputValue || inputValue === "") && (
            <CustomButton
              text={t("Add New Form")}
              style={{
                my: 5,
                mx: "auto",
                width: onlySmallScreen ? "100%" : "300px",
              }}
              type="submit"
              onClickFunction={() => setValue("formId", 0)}
            />
          )}
          {inputValue && inputValue !== "" && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 5 }}>
              <CustomButton
                text={t("Update Form")}
                style={{ m: 2 }}
                type="submit"
              />
            </Box>
          )}
          <Typography sx={{ my: 4, fontWeight: "bold" }}>
            {t("Form Questions")}
          </Typography>
          <FieldArray />
          <Divider />
        </form>
      </FormProvider>
      <div>
        <ChangeParametersModal
          openError={openError}
          setOpenError={setOpenError}
          openSuccess={openSuccess}
          setOpenSuccess={setOpenSuccess}
          successMessage={t("Successful Edit Form")}
          errorMessage={t("Error Edit Form")}
        />
      </div>
    </div>
  );
}

export default EditSingleForm;
