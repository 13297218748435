import { useQuery, UseQueryOptions } from "react-query";
import { getApiClient } from "helpers/Global";
import { AxiosError } from "axios";
import { operations } from "schema/schema";

const path = "api/v1/users";
const queryKey = "filteredUsers";

type Response =
  operations["api_apiv1users_get_collection"]["responses"]["200"]["content"]["application/ld+json"];
type Parameter = operations["api_apiv1users_get_collection"]["parameters"];

const getFilteredUsers = async (parameters: Parameter) => {
  const { data } = await getApiClient().get(
    `${path}?deletedDate=0000-00-00 00:00:00`,
    {
      params: parameters?.query,
    }
  );
  return data;
};

export default function useUsersFilterQuery(
  parameters: Parameter,
  options: UseQueryOptions<Response, AxiosError> = {}
) {
  return useQuery<Response, AxiosError>(
    [queryKey, parameters],
    () => getFilteredUsers(parameters),
    options
  );
}
